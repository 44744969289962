// @ts-nocheck
import { t } from "@lingui/macro";
import { useState } from "react";
import { Button, FormControl } from "react-bootstrap";
import Form from 'react-bootstrap/Form';
import { useHistory } from "react-router";
import { AddressPrefix, addressToPuzzle, baseUrl, getAddress, getXCHAddressFromTXCH, puzzleToAddress } from "../../shared";

const SearchBar = (props) => {
    const [query, setQuery] = useState('');
    const history = useHistory();


    const onDarkModeToggle = (darkMode: boolean) => {
        dispatch(setDarkModeAction({ darkMode }));
    };

    const onSearch = (e) => {
        e.preventDefault();
        var normalizedQuery = query.trim();
        normalizedQuery = normalizedQuery.replaceAll(/,/g, '');
        if (!normalizedQuery) {
            return;
        }
        let url;
        if (normalizedQuery.toLowerCase().startsWith(AddressPrefix.Mainnet)) {
            //console.log('redirect to main if not')
            //redirect to mainnet if not
            url = baseUrl + 'api/search?address=' + normalizedQuery;
        }
        else if (normalizedQuery.toLowerCase().startsWith(AddressPrefix.Testnet)) {
            //console.log('redirect to testnet if not')
            //if (window.location.hostname == 'localhost'){
            //  const newUrl = testnetFrontendURL + '/addresses/' + normalizedQuery
            //  console.log(newUrl);
            //  window.location.href = newUrl;
            //}
            //redirect to testnet if not 
            url = baseUrl + 'api/search?address=' + getXCHAddressFromTXCH(normalizedQuery);
        } else if (normalizedQuery.toLowerCase().startsWith(AddressPrefix.DID)) {
            url = baseUrl + 'api/search?hash=' + addressToPuzzle(normalizedQuery);
        } else if (normalizedQuery.toLowerCase().startsWith(AddressPrefix.NFT)) {
            url = baseUrl + 'api/search?hash=' + addressToPuzzle(normalizedQuery);
        } else if (!isNaN(+normalizedQuery)) {
            url = baseUrl + 'api/search?hash=' + normalizedQuery + '&address=' + puzzleToAddress(normalizedQuery, 'xch');
        } else if (normalizedQuery.toLowerCase().startsWith('0xa0')) {
            url = baseUrl + 'api/search?hash=' + normalizedQuery;
        } else if (normalizedQuery.length < 16) {
            url = baseUrl + 'api/search?code=' + normalizedQuery;
        } else {
            url = baseUrl + 'api/search?hash=' + normalizedQuery + '&address=' + puzzleToAddress(normalizedQuery, 'xch');
        }

        //http://localhost:5000/api/search?hash=0xDCF87B95893476427E1914342B5B9375443173B65FBD56DA4F177EEED35F51D&address=xch1uwu6m38tp8gcmqaa2eyz4mjkdqs0tt7uuk2m8mgft6ekch80jvqswdhmlh

        let responseData;
        fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error("Error connecting")
            })
            .then((data) => {
                if (data.type === 'block') {
                    history.push(`/blocks/${data.block.blockHeight}`);
                } else if (data.type === 'address') {
                    history.push(`/addresses/${getAddress(data.address.address)}`);
                } else if (data.type === 'coin') {
                    history.push(`/coins/${data.coin.coinId}`);
                } else if (data.type === 'cat') {
                    history.push(`/cat/${data.cat.tail}`);
                }
            }).catch((err) => {
                history.push('/404')
            });
    }
    return (<div>
        {props.src == 'home' ?
            <div>
                <div className="search-box-container">
                    <FormControl onKeyDown={(ev) => {
                        if (ev.key === 'Enter')
                            onSearch(ev)
                    }} onChange={(ev) => setQuery(ev.target.value)} type="text" placeholder={t`Search Address, CAT, Block, or Coin`} id="searchbox" className="mr-sm-2" />
                </div>
                <Button onClick={onSearch} variant="outline-success">{t`Search`}</Button>
            </div>
            :
            <Form inline onSubmit={onSearch}>

                {props.hideSearch ? <></> : <>
                    <FormControl onKeyDown={(ev) => { if (ev.key === 'Enter') onSearch(ev) }} onChange={(ev) => { setQuery(ev.target.value) }} type="text" placeholder={t`Block, CAT or wallet address`} className="mr-sm-2" tabIndex={0} />
                    <Button onClick={onSearch} variant="outline-success"> Search</Button></>}
            </Form>

        }

    </div>)


}

export default SearchBar;