import { addressToPuzzle, puzzleToAddress, getAddressFromPuzzle, testnet, AddressPrefix } from '../shared'
import ToolTipIcon from '../components/tool-tip-icon';
import { useState } from 'react';

function AddressPuzzleConverterPage() {
    const [prefix, setPrefix] = useState(testnet ? AddressPrefix.Testnet: AddressPrefix.Mainnet) as any;
    function updateAddress(e) {
        let usedPrefix = prefix;
        switch (e.target.value) {
            case 'testnet':
                setPrefix(AddressPrefix.Testnet);
                usedPrefix = AddressPrefix.Testnet;
                break;
            case 'mainnet':
                setPrefix(AddressPrefix.Mainnet);
                usedPrefix = AddressPrefix.Mainnet;
                break;
            case 'nft':
                setPrefix(AddressPrefix.NFT);
                usedPrefix = AddressPrefix.NFT;
                break;
            case 'did':
                setPrefix(AddressPrefix.DID);
                usedPrefix = AddressPrefix.DID;
                break;
            default:
                usedPrefix = testnet ? AddressPrefix.Testnet: AddressPrefix.Mainnet;
                setPrefix(usedPrefix);
        }
        // console.log(`Using prefix ${prefix} from selection ${e.target.value}`);
        if ((document.getElementById('address') as HTMLInputElement).value || (document.getElementById('puzzlehash') as HTMLInputElement).value) {
            let address = puzzleToAddress((document.getElementById('puzzlehash') as HTMLInputElement).value?.trim(), usedPrefix);
            // console.log(address);
            (document.getElementById('address') as HTMLInputElement).value = address
        }
    }
    return (
        <div className='page'>
            <span>Address: </span>
            <input size={70} id='address' type='text' placeholder='(example) xch1k2uedw6fxlsvld8zfffkrpnled5f942mrf90vkln96hhc3574qjshdf4pq' onChange={(e) => {
                (document.getElementById('puzzlehash') as HTMLInputElement).value = addressToPuzzle(e.target.value?.trim());
            }}></input>
            <br />
            <span>Hex or Puzzlehash: </span>
            <input size={70} id='puzzlehash' type='text' placeholder='(example) b2b996bb4937e0cfb4e24a5361867fcb6892d55b1a4af65bf32eaf7c469ea825' onChange={updateAddress}>
            </input>
            <br />
            <br />
            <p>Choose Address Format<span style={{ marginLeft: '5px' }}><ToolTipIcon explanation="This will allow you to change between xch and txch1 or other format addresses. As an example, if you wish to retrieve a testnet address from a puzzlehash, you can select the testnet option. If you are going from an address to a puzzlehash, you can ignore this." /></span></p>
            <div onChange={updateAddress}>
                <input type='radio' value='mainnet' name='network' defaultChecked={!testnet}></input>
                <label htmlFor="mainnet">&nbsp;&nbsp;Mainnet Address</label><br></br>
                <input type='radio' value='testnet' name='network' defaultChecked={testnet}></input>
                <label htmlFor="testnet">&nbsp;&nbsp;Testnet Address</label><br></br>
                <input type='radio' value='nft' name='network'></input>
                <label htmlFor="nft">&nbsp;&nbsp;NFT Address</label><br></br>
                <input type='radio' value='did' name='network'></input>
                <label htmlFor="did">&nbsp;&nbsp;DID Address</label><br></br>
            </div>
        </div>)
}

export default AddressPuzzleConverterPage;