// @ts-nocheck
import BackgroundImg from '../../images/background-landing.png';
import './landing-page.scss';
import RecentBlocksRefresh from '../../components/recent-blocks/recent-blocks-refresh';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../store';
import { Button, FormControl } from 'react-bootstrap';
import { t } from '@lingui/macro';
import { useState } from 'react';
import { useHistory } from 'react-router';
import { baseUrl, puzzleToAddress } from '../shared';
import SearchBar from '../../components/search-bar/search-bar';
import Stats from '../../components/stats/stats';
import { isEmbedMode } from '../../utils/navigator-util';

export const LandingPage = () => {
    const history = useHistory();
    const [query, setQuery] = useState<string>('');

    return (
        <div className="landing-page">
            {/* <h5
                style={{
                    marginTop: '20px',
                    padding: '10px',
                    fontSize: '30px',
                    backgroundColor: 'darkgray',
                    borderRadius: '10px',
                    backgroundColor: 'slategray',
                }}
            >
                Data syncing may be paused as we are transitioning out of beta,
                we'll be back 😀
              </h5>*/}
            <br />
            {!isEmbedMode() && <SearchBar src="home" hideSearch={true} />}
            <Stats />
            <RecentBlocksRefresh />
            {/*<img className="background-image" src={BackgroundImg} alt="" />*/}
        </div>
    );
};
