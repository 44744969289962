import classNames from "classnames";
import { useState } from "react";

import './toggle-header.scss';

export const ToggleHeader = (props: { label: string, altLabel: string, onClick?: (isAltActive: boolean) => void }) => {
    const [isAltActive, setIsAltActive] = useState(false);

    const handleClick = () => {
        setIsAltActive(!isAltActive);
        props.onClick && props.onClick(!isAltActive);
    };

    return (
        <div className={classNames('toggle-header', isAltActive && 'alt')} onClick={handleClick}>
            <span className="main-label">{props.label}</span> | <span className="alt-label">{props.altLabel}</span>
        </div>
    );
};