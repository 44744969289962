import { ICATCoinData, ICoinData } from '../coin/coin-page';

export interface ICATData {
    activeAddressCount: number;
    tail: string;
    lastBlockHeight: number;
    firstBlockHeight: number;
    updatedDayHour: string;
    updatedTimestamp: string;
    confirmed: boolean;
    transactionCount: number;
    removalsCount: number;
    totalAddedCats: number;
    totalRemovedCats: number;
    totalAddressCount: number;

    name?: string;
    description?: string;
    logoUrl?: string;
    code?: string;
    category?: string;
    supply?: number;
    websiteUrl?: string;
    twitterUrl?: string;
    discordUrl?: string;
    volume?: number;
}

export const TryCoinAsCATCoin = (coin: ICoinData) => (coin as ICATCoinData).coinType === 'cat_v2' ? (coin as ICATCoinData) : undefined;

export const NUM_FORMAT = new Intl.NumberFormat();

export const PAD_0X = (str: string) => str.startsWith('0x') ? str.toLocaleLowerCase() : `0x${str.toLocaleLowerCase()}`;

export const GlobalSettings = {
    coinFilter: 'xch' as ('xch' | 'cats'),
};

export const FORMAT_TAIL = (tail: string) =>
    tail.startsWith('0xa0')
        ? tail.substring(4)
        : tail.startsWith('a0')
            ? tail.substring(2)
            : tail;

export const CAT_VALUE_FROM_COIN = (coin: ICATCoinData | undefined) => coin ? `${coin.val / 1000} ${coin.catDetails.code || 'CAT'}` : '0 CAT';