import { Trans } from '@lingui/macro';
import { I18nProvider } from '@lingui/react';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Provider, useSelector } from "react-redux";
import { BrowserRouter as Router, Link, Route, Switch, useHistory } from "react-router-dom";
import PullToRefresh from 'react-simple-pull-to-refresh';
import BlockPage from '../src/pages/block/block-page';
import { StatisticsPage } from '../src/pages/statistics/statistics-page';
import ScrollToTop from '../src/utils/scroll-to-top';
import './bootstrap.scss';
import { currencyCookieName } from './components/currency-picker/currency-picker';
import NotFound from './components/not-found/not-found';
import RecentBlocks from './components/recent-blocks/recent-blocks';
import { TopBar } from './components/top-bar/top-bar';
import { activateLocale, i18n } from './config/locales';
import useLocale from './hooks/useLocale';
import DownloadAndroidImg from './images/download-android-app.png';
import DownloadIOSImg from './images/download-ios-app.png';
import { RootState } from './index.reducers';
import './index.scss';
import { AboutPage } from './pages/about/about-page';
import AccountPage from './pages/account/account-page';
import AddressPuzzleConverterPage from './pages/address-puzzle-converter-page';
import AddressPage from './pages/address/address-page';
import { CATPage } from './pages/cats/cat-page';
import { CATsPage } from './pages/cats/cats-page';
import TransactionPage from './pages/coin/coin-page';
import { LandingPage } from './pages/landing/landing-page';
import { PrivacyPage } from './pages/privacy/privacy-page';
import RegisterPage from './pages/register/register-page';
import { TermsPage } from './pages/terms/terms-page';
import reportWebVitals from './report-web-vitals';
import { store } from "./store";
import { getCookie } from './utils/cookie-manager';
import { exchangeToken } from './utils/login-util';
import { handleMobileAppNavigation, isEmbedMode } from './utils/navigator-util';
import { getParameterByName } from './utils/url-util';

const App = () => {
    const [hideSearch, setHideSearch] = useState<boolean>(document.location.pathname === '/');
    const history = useHistory();
    const [locale] = useLocale();
    const isEmbed = isEmbedMode();
    const [loggedIn, setLoggedIn] = useState(true);

    const darkMode = useSelector((state: RootState) => state.topLevel.darkMode);
    const [showGooglePlay, setShowGooglePlay] = useState(false);
    const currency = getCookie(currencyCookieName);
    if (document.location.hash) {
        history.replace(`/${document.location.hash.substr(1)}`);
    }

    var spLoginToken = getParameterByName('spLoginToken');
    var spLauncherId = getParameterByName('launcherId');
    if (spLoginToken && spLauncherId) {
        exchangeToken(spLoginToken, spLauncherId);
    }

    var loginToken = getParameterByName('loginToken');
    var tokenExpiration = getParameterByName('expirationDate');
    var accountId = getParameterByName('accountId');
    if (loginToken && tokenExpiration && accountId) {
        localStorage.setItem('loginToken', loginToken);
        localStorage.setItem('expirationDate', tokenExpiration);
        localStorage.setItem('accountId', accountId);
    }

    const updateHtmlBackgroundColor = (darkMode: boolean) => {
        // Adding height: 100% to the <html> tag was having side effects; however, 
        // we want the background color to expand to <html>, so this is a workaround 
        // to achieve that
        const htmlTag = document.querySelector('html');
        if (htmlTag) {
            htmlTag.style.backgroundColor = darkMode ? 'black' : 'transparent';
        }
    };

    useEffect(() => {
        activateLocale(locale);
        updateHtmlBackgroundColor(darkMode);
    }, [locale, darkMode]);

    useEffect(() => {
        const unlisten = history.listen(() => {
            const { pathname } = document.location;
            setHideSearch(pathname === '/');
        });

        // stop the listener when component unmounts
        return unlisten;
    }, []);

    useEffect(() => {
        localStorage.getItem('loginToken') ? setLoggedIn(true) : setLoggedIn(false)

    }, []);

    const handleRefresh = (): Promise<void> => {
        return new Promise(res => {
            setTimeout(() => {
                res();
                window.location.reload();
            }, 1000);
        });
    };

    return (
        <div className={classNames(`app lang-${locale}`, `curr-${currency}`, darkMode && ' dark-mode', isEmbed && ' embed')}>
            <PullToRefresh
                onRefresh={handleRefresh}
                pullingContent=''>
                <>
                    <ScrollToTop />
                    {!isEmbed && <TopBar key={Date.now()} loggedIn={loggedIn} hideSearch={hideSearch} />}
                    <div className="page-content">
                        <Switch>
                            <Route exact path="/">
                                <LandingPage />
                            </Route>
                            <Route path="/blocks/:id" component={props => (<BlockPage key={Date.now()} />)} />
                            <Route path="/blocks" component={props => (<RecentBlocks key={Date.now()} />)} />
                            <Route path="/addresses/:addressParam" component={props => (<AddressPage key={Date.now()} location={props.location} />)} />
                            <Route path="/coins/:id" component={props => (<TransactionPage key={Date.now()} />)} />
                            <Route path="/statistics" component={props => (<StatisticsPage key={Date.now()} />)} />
                            <Route path="/privacy" component={props => (<PrivacyPage />)} />
                            <Route path="/about" component={props => (<AboutPage />)} />
                            <Route path="/terms" component={props => (<TermsPage />)} />
                            <Route path="/login" component={props => (<RegisterPage key={Date.now()} />)} />
                            <Route path="/account" component={(props) => {
                                localStorage.getItem('loginToken') ? setLoggedIn(true) : setLoggedIn(false)/*refresh*/
                                return <AccountPage key={Date.now()} />
                            }} />
                            <Route path="/logout" component={() => { localStorage.removeItem('loginToken'); history.push('/'); setLoggedIn(false); return <div></div> }} />
                            <Route path='/address-puzzlehash-converter' component={props => (<AddressPuzzleConverterPage key={Date.now()} />)} />
                            <Route path='/cats' component={props => <CATsPage />} />
                            <Route path='/cat/:tail' component={props => <CATPage />} />
                            <Route path='*'>
                                <NotFound />
                            </Route>
                            {/* If none of the previous routes render anything,
              this route acts as a fallback.

              Important: A route with path="/" will *always* match
              the URL because all URLs begin with a /. So that's
              why we put this one last of all */}

                        </Switch>
                    </div>
                    {!isEmbed && <div className='footer'>
                        <div className="footer-links">
                            <Link to="/terms"><Trans>Terms</Trans></Link>
                            <Link to="/privacy"><Trans>Privacy</Trans></Link>
                            <Link to="/about"><Trans>About</Trans></Link>
                        </div>
                        <h6 className='footer-content'>
                            <Trans>© <span>{new Date().getFullYear()}</span> <a href='https://pool.space'>Space Pool, LLC</a> - All Rights Reserved</Trans>
                        </h6>
                        <div className='social-icons'>
                            <a aria-label={`Discord chat`} onClick={(ev: any) => handleMobileAppNavigation(ev, 'https://pool.space/discord')} href='https://pool.space/discord' target="_blank" rel="noopener noreferrer">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M297.216 243.2c0 15.616-11.52 28.416-26.112 28.416-14.336 0-26.112-12.8-26.112-28.416s11.52-28.416 26.112-28.416c14.592 0 26.112 12.8 26.112 28.416zm-119.552-28.416c-14.592 0-26.112 12.8-26.112 28.416s11.776 28.416 26.112 28.416c14.592 0 26.112-12.8 26.112-28.416.256-15.616-11.52-28.416-26.112-28.416zM448 52.736V512c-64.494-56.994-43.868-38.128-118.784-107.776l13.568 47.36H52.48C23.552 451.584 0 428.032 0 398.848V52.736C0 23.552 23.552 0 52.48 0h343.04C424.448 0 448 23.552 448 52.736zm-72.96 242.688c0-82.432-36.864-149.248-36.864-149.248-36.864-27.648-71.936-26.88-71.936-26.88l-3.584 4.096c43.52 13.312 63.744 32.512 63.744 32.512-60.811-33.329-132.244-33.335-191.232-7.424-9.472 4.352-15.104 7.424-15.104 7.424s21.248-20.224 67.328-33.536l-2.56-3.072s-35.072-.768-71.936 26.88c0 0-36.864 66.816-36.864 149.248 0 0 21.504 37.12 78.08 38.912 0 0 9.472-11.52 17.152-21.248-32.512-9.728-44.8-30.208-44.8-30.208 3.766 2.636 9.976 6.053 10.496 6.4 43.21 24.198 104.588 32.126 159.744 8.96 8.96-3.328 18.944-8.192 29.44-15.104 0 0-12.8 20.992-46.336 30.464 7.68 9.728 16.896 20.736 16.896 20.736 56.576-1.792 78.336-38.912 78.336-38.912z"></path></svg>
                            </a>
                            <a style={{ display: 'none' }} onClick={(ev: any) => handleMobileAppNavigation(ev, 'https://www.reddit.com/r/thespacepool')} aria-label={`Reddit community page`} href="https://www.reddit.com/r/thespacepool" target="_blank" rel="noopener noreferrer"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M201.5 305.5c-13.8 0-24.9-11.1-24.9-24.6 0-13.8 11.1-24.9 24.9-24.9 13.6 0 24.6 11.1 24.6 24.9 0 13.6-11.1 24.6-24.6 24.6zM504 256c0 137-111 248-248 248S8 393 8 256 119 8 256 8s248 111 248 248zm-132.3-41.2c-9.4 0-17.7 3.9-23.8 10-22.4-15.5-52.6-25.5-86.1-26.6l17.4-78.3 55.4 12.5c0 13.6 11.1 24.6 24.6 24.6 13.8 0 24.9-11.3 24.9-24.9s-11.1-24.9-24.9-24.9c-9.7 0-18 5.8-22.1 13.8l-61.2-13.6c-3-.8-6.1 1.4-6.9 4.4l-19.1 86.4c-33.2 1.4-63.1 11.3-85.5 26.8-6.1-6.4-14.7-10.2-24.1-10.2-34.9 0-46.3 46.9-14.4 62.8-1.1 5-1.7 10.2-1.7 15.5 0 52.6 59.2 95.2 132 95.2 73.1 0 132.3-42.6 132.3-95.2 0-5.3-.6-10.8-1.9-15.8 31.3-16 19.8-62.5-14.9-62.5zM302.8 331c-18.2 18.2-76.1 17.9-93.6 0-2.2-2.2-6.1-2.2-8.3 0-2.5 2.5-2.5 6.4 0 8.6 22.8 22.8 87.3 22.8 110.2 0 2.5-2.2 2.5-6.1 0-8.6-2.2-2.2-6.1-2.2-8.3 0zm7.7-75c-13.6 0-24.6 11.1-24.6 24.9 0 13.6 11.1 24.6 24.6 24.6 13.8 0 24.9-11.1 24.9-24.6 0-13.8-11-24.9-24.9-24.9z"></path></svg>
                            </a>
                            <a aria-label={`Twitter account`} onClick={(ev: any) => handleMobileAppNavigation(ev, 'https://twitter.com/thespacepool')} href="https://twitter.com/thespacepool" target="_blank" rel="noopener noreferrer">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg>
                            </a>
                            <a aria-label={`Medium articles`} onClick={(ev: any) => handleMobileAppNavigation(ev, 'https://blog.pool.space/')} href="https://blog.pool.space/" target="_blank" rel="noopener noreferrer">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M0 32v448h448V32H0zm372.2 106.1l-24 23c-2.1 1.6-3.1 4.2-2.7 6.7v169.3c-.4 2.6.6 5.2 2.7 6.7l23.5 23v5.1h-118V367l24.3-23.6c2.4-2.4 2.4-3.1 2.4-6.7V199.8l-67.6 171.6h-9.1L125 199.8v115c-.7 4.8 1 9.7 4.4 13.2l31.6 38.3v5.1H71.2v-5.1l31.6-38.3c3.4-3.5 4.9-8.4 4.1-13.2v-133c.4-3.7-1-7.3-3.8-9.8L75 138.1V133h87.3l67.4 148L289 133.1h83.2v5z"></path></svg>
                            </a>
                            <a style={{ display: 'none' }} aria-label={`Github repositories`} onClick={(ev: any) => handleMobileAppNavigation(ev, 'https://github.com/space-pool')} href="https://github.com/space-pool" target="_blank" rel="noopener noreferrer">
                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 496 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"></path></svg>
                            </a>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
                            <a href="https://apps.apple.com/us/app/space-explorer-chia-blockchain/id1635509150" target="_blank" rel="noreferrer">
                                <img className="download-ios" src={DownloadIOSImg} alt={`Download iOS App`} /></a>

                            <a href="https://play.google.com/store/apps/details?id=com.spacepool.spaceexplorer" target="_blank" rel="noreferrer">
                                <img className="download-android" src={DownloadAndroidImg} alt={`Download Android App`} /></a>
                        </div>
                    </div>}
                </>
            </PullToRefresh>
        </div>
    );
};

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <I18nProvider i18n={i18n}>
                <React.StrictMode>
                    <App />
                </React.StrictMode>
            </I18nProvider>
        </Router>
    </Provider>,
    document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
