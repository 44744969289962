import { createAction } from "typesafe-actions";
import PoolStats, { IPoolStatHistory } from "./models/pool-stats";
import PoolHistory from "./models/PoolHistory";
import Quote from "./models/quote";

// NOTE
// DO NOT USE dynamic string operations(like template string) as action type property.
// see more details: https://github.com/piotrwitek/typesafe-actions#--the-actions;
export const CHIA_QUOTE_GET = "@@chiaQuote/GET";
export const CHIA_QUOTE_SET = "@@chiaQuote/SET";
export const CHIA_QUOTE_ERROR = "@@chiaQuote/ERROR";
export const STATISTICS_GET = "@@statistics/GET";
export const STATISTICS_SET = "@@statistics/SET";
export const STATISTICS_ERROR = "@@statistics/ERROR";
export const DARK_MODE_SET = "@@/darkMode/SET";
export const POOL_STATS_GET = "@@poolStats/GET";
export const POOL_STATS_SET = "@@poolStats/SET";
export const POOL_STATS_ERROR = "@@poolStats/ERROR";

// top level
export const setDarkModeAction = createAction(
    DARK_MODE_SET,
    resolve => (payload: { darkMode: boolean }) => resolve({ ...payload }),
);


// chia quote
export const chiaQuoteGetAction = createAction(
    CHIA_QUOTE_GET,
    (resolve) => () => resolve({})
);

export const chiaQuoteSetAction = createAction(
    CHIA_QUOTE_SET,
    (resolve) => (data: Quote) => resolve(data)
);

export const chiaQuoteErrorAction = createAction(
    CHIA_QUOTE_ERROR,
    (resolve) => (error: Error) => resolve(error)
);

// statistics
export const statisticsGetAction = createAction(
    STATISTICS_GET,
    (resolve) => () => resolve()
);

export const statisticsSetAction = createAction(
    STATISTICS_SET,
    (resolve) => (data: PoolHistory) => resolve(data));

export const statisticsErrorAction = createAction(
    STATISTICS_ERROR,
    (resolve) => (error: Error) => resolve(error)
);

// pool stats
export const poolStatsGetAction = createAction(
    POOL_STATS_GET,
    (resolve) => () => resolve({})
);

export const poolStatsSetAction = createAction(
    POOL_STATS_SET,
    (resolve) => (data: PoolStats) => resolve(data));

export const poolStatsErrorAction = createAction(
    POOL_STATS_ERROR,
    (resolve) => (error: Error) => resolve(error)
);

// pool stats history
export const poolStatHistoryGetAction = createAction(
    '@@poolStatsHistory/GET',
    (resolve) => () => resolve({})
);

export const poolStatHistorySetAction = createAction(
    '@@poolStatsHistory/SET',
    (resolve) => (data: IPoolStatHistory[]) => resolve(data));

export const poolStatHistoryErrorAction = createAction(
    '@@poolStatsHistory/ERROR',
    (resolve) => (error: Error) => resolve(error)
);