import { t, Trans } from '@lingui/macro';
import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { ToggleSwitch } from '../../components/toggle-switch/toggle-switch';
import { activateLocale, locales } from '../../config/locales';
import useLocale from '../../hooks/useLocale';
import LogoImg from '../../images/logo.png';
import { setDarkModeAction } from '../../index.actions';
import { RootState } from '../../index.reducers';
import { mainnetFrontendURL, testnet, testnetFrontendURL } from '../../shared';
import { ChiaPriceTicker } from '../chia-price-ticker/chia-price-ticker';
import SearchBar from '../search-bar/search-bar';
import './top-bar.scss';

export const TopBar = (props: { hideSearch: boolean, loggedIn: boolean }) => {
    const history = useHistory();
    const [locale, setLocale] = useLocale();
    const [query, setQuery] = useState<string>('');
    const dispatch = useDispatch();

    const darkMode = useSelector((state: RootState) => state.topLevel.darkMode);

    var getLocaleLabel = (locale: string): string => {
        var localeLabel = '';
        for (let entry of locales) {
            if (entry.locale === locale) {
                localeLabel = entry.label;
                break;
            }
        }
        return localeLabel;
    };
    const [localeLabel, setLocaleLabel] = React.useState(getLocaleLabel(locale));

    var onLanguageClick = (ev: any) => {
        var newLocale = ev.target.dataset.locale;
        var newLabel = getLocaleLabel(newLocale);
        setLocale(newLocale);
        setLocaleLabel(newLabel);
        activateLocale(newLocale);
        ev.preventDefault();
    };

    //useEffect(() => {}, [localStorage.getItem('loginToken')]);

    const onDarkModeToggle = (darkMode: boolean) => {
        dispatch(setDarkModeAction({ darkMode }));
    };

    const getLoggedInView = () => {
        return (<NavDropdown title='Account' id="collasible-nav-dropdown">
            <NavDropdown.Item><Link to="/account"><Trans>Settings</Trans></Link></NavDropdown.Item>
            <NavDropdown.Item><Link to="/logout"><Trans>Logout</Trans></Link></NavDropdown.Item>
        </NavDropdown>);
    }

    const getLoginLinkView = () => {
        return (<Nav.Link><Link to="/login"><Trans>Login</Trans></Link></Nav.Link>);
    }

    return (
        <Navbar collapseOnSelect expand="lg" bg={darkMode ? "dark" : ""} sticky="top" >
            <Navbar.Brand> <Link to="/">
                <img
                    src={LogoImg}
                    height="50"
                    className="d-inline-block align-top"
                    alt="Space Explorer"
                /></Link>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav>
                    <Nav.Link><Link to="/"><Trans>Home</Trans></Link></Nav.Link>
                    <Nav.Link><Link to="/cats"><Trans>CATs</Trans></Link></Nav.Link>
                    <Nav.Link><Link to="/statistics"><Trans>Statistics</Trans></Link></Nav.Link>
                    <NavDropdown title='Tools' id="collasible-nav-dropdown">
                        <NavDropdown.Item><Link to="/address-puzzlehash-converter"><Trans>Address / Puzzlehash Converter</Trans></Link></NavDropdown.Item>
                    </NavDropdown>

                    {!testnet && props.loggedIn ? getLoggedInView() : null}
                    {!testnet && !props.loggedIn ? getLoginLinkView() : null}
                    {/* {testnet ? <Nav.Link href={mainnetFrontendURL}><Trans>Mainnet</Trans></Nav.Link> : <Nav.Link href={testnetFrontendURL}><Trans>Testnet10</Trans></Nav.Link>} */}
                    {/*<NavDropdown title={localeLabel} id="collasible-nav-dropdown">
                        {locales && locales.map((entry, i) =>
                            <NavDropdown.Item key={entry.locale} href={document.location.href} data-locale={entry.locale} onClick={onLanguageClick}>{entry.label}</NavDropdown.Item>
                        )}
                        <NavDropdown.Item key="contribute" href="https://crowdin.com/project/space-pool"><Trans>Help translate</Trans></NavDropdown.Item>
                        </NavDropdown>*/}
                </Nav>
                <Nav className="mr-auto">

                </Nav>
                {testnet ? <div className="label">Testnet10</div> : null}
                <ChiaPriceTicker />
                <ToggleSwitch label={t`Dark Mode`} initialState={darkMode} onChange={onDarkModeToggle} />&nbsp;
                <SearchBar hideSearch={props.hideSearch} />
            </Navbar.Collapse>
        </Navbar>
    );
};