import { combineReducers } from "redux";
import { createReducer } from "typesafe-actions";
import {
    chiaQuoteReducer, ChiaQuoteState, poolStatsReducer, PoolStatsState
} from './pages/landing/landing-page.reducers';
import { statisticsReducer, StatisticsState } from "./pages/statistics/statistics-page.reducers";
import { actions, ActionsType } from "./store";
import { getCookie } from "./utils/cookie-manager";
import { isEmbedMode } from "./utils/navigator-util";


export interface TopLevelState {
    darkMode: boolean;
}

export const topLevelReducer
    = createReducer<TopLevelState, ActionsType>({
        darkMode: (!isEmbedMode() && !getCookie('darkMode')) || getCookie('darkMode') === '1' || (isEmbedMode() && navigator.userAgent.indexOf('dark') >= 0),
    })
        .handleAction(actions.setDarkModeAction, (state, action) => ({
            ...state,
            darkMode: action.payload.darkMode,
        }));

export type RootState = {
    chiaQuote: ChiaQuoteState;
    poolStats: PoolStatsState;
    statistics: StatisticsState;
    topLevel: TopLevelState;
};

const reducers = combineReducers({
    chiaQuote: chiaQuoteReducer,
    poolStats: poolStatsReducer,
    statistics: statisticsReducer,
    topLevel: topLevelReducer,
});

export default reducers;