import { createReducer } from "typesafe-actions";
import { actions, ActionsType } from "../../store";
import PoolHistory from "../../models/PoolHistory";

export const initialState = {};

export interface StatisticsState {
  statistics?: PoolHistory
}

export const statisticsReducer = createReducer<StatisticsState, ActionsType>(
  initialState
)
.handleAction(actions.statisticsSetAction, (state, action) => { 
  return ({
  ...state,
  statistics: new PoolHistory(action.payload),
})})
.handleAction(actions.statisticsErrorAction, (state, action) => {
  console.error(action.payload);
  return state;
});
