import { t } from '@lingui/macro';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { CopyIcon } from '../../components/copy-icon/copy-icon';
import DateToggleValue from '../../components/date-toggle-value';
import NotFound from '../../components/not-found/not-found';
import { SpaceConstraintCATListItem, SpaceConstraintCoinListItem, SpaceConstraintTable } from '../../components/space-constraint-list/space-constraint-list';
import { SpacePoolTable } from '../../components/spacepool-table/spacepool-table';
import XCHToggleValue from '../../components/xch-toggle-value';
import { addressToPuzzle, baseUrl, formatXCH, getAddress, getXCHAddressFromTXCH, knownAddresses } from '../../shared';
import { CATProfile } from '../cats/cat-profile';
import { FORMAT_TAIL, GlobalSettings, NUM_FORMAT, TryCoinAsCATCoin } from '../cats/cat-utils';
import { ICoinData } from '../coin/coin-page';
import './address-page.scss';
import { AddressWarningIcon } from './address-warning-icon';

interface IAddressData {
    address: string;
    balance: number;
    confirmed: boolean;
    firstBlockHeight: number;
    lastBlockHeight: number;
    removalsCount: number;
    rewardCount: number;
    totalAddedMojo: number;
    totalRemovedMojo: number;
    totalRewardMojo: number;
    transactionCount: number;
    updatedDayHour: string;
    updatedTimestamp: number;

    innerAddress?: string;
}

interface IAddressCATData extends IAddressData {
    firstBlockHeightCats: number;
    lastBlockHeightCats: number;
    removalsCountCats: number;
    totalAddedCats: number;
    totalRemovedCats: number;
    transactionCountCats: number;
}

export interface ICATBalance {
    address: string;
    outerAddress: string;
    tail: string;
    balance: number;
    firstBlockHeight: number;
    lastBlockHeight: number;
    updatedDayHour: string;
    updatedTimestamp: number;
    confirmed: boolean;
    transactionCount: number;
    removalsCount: number;
    totalAddedCats: number;
    totalRemovedCats: number;
}

function AddressPage(props) {
    const [address, setAddress] = useState<IAddressData>();
    const [isLoading, setIsLoading] = useState(true);
    const [continuationTokenTo, setContinuationTokenTo] = useState('') as any;
    const [continuationTokenFrom, setContinuationTokenFrom] = useState('') as any;
    const [transactionsTo, setToTransactions] = useState<ICoinData[]>([]);
    const [transactionsFrom, setFromTransactions] = useState<ICoinData[]>([]);
    const [isLoadingTransactions, setIsLoadingTransactions] = useState(true);
    const [hideButtonTo, setHideButtonTo] = useState(false);
    const [hideButtonFrom, setHideButtonFrom] = useState(false);
    const { addressParam }: { addressParam: string } = useParams();
    const [err, setErr] = useState(false) as any;
    const [coinFilter, setCoinFilter] = useState<typeof GlobalSettings.coinFilter>(GlobalSettings.coinFilter);
    const [catBalances, setCATBalances] = useState<ICATBalance[]>([]);

    const addressCatData = (address as IAddressCATData)?.firstBlockHeightCats ? (address as IAddressCATData) : undefined;

    useEffect(() => window.scrollTo(0, 0), []);

    function handleCoinFilterChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setFromTransactions([]);
        setToTransactions([]);
        setCoinFilter(e.currentTarget.value as any || 'xch');
        GlobalSettings.coinFilter = (e.currentTarget.value as any) || 'xch';
    }

    function loadAddressData(event) {
        setIsLoading(true);
        setIsLoadingTransactions(true);
        //
        let url = ''
        //console.log('param', addressParam)
        // console.log('hostname', window.location.hostname)
        if (addressParam.toLowerCase().startsWith('txch1')) {
            //if (window.location.hostname == 'localhost'){
            //    const newUrl = testnetFrontendURL + '/addresses/' + addressParam
            //    window.location.href = newUrl;
            //}
            //console.log('redirect to testnet if not')
            url = baseUrl + 'api/addresses/' + getXCHAddressFromTXCH(addressParam)
        } else if (addressParam.toLowerCase().startsWith('xch')) {
            //if (window.location.hostname == testnetFrontendURL){
            //    window.location.assign(mainnetFrontendURL);
            //}
            //console.log('redirect to mainnet if not)
            url = baseUrl + 'api/addresses/' + addressParam;
        }

        //console.log(url)
        fetch(url)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setIsLoading(false);
                data.address = getAddress(data.address)
                setAddress(data);
                if ((data as IAddressCATData).firstBlockHeightCats) {
                    if (GlobalSettings.coinFilter === 'cats') {
                        setCoinFilter('cats');
                    }

                    fetch(
                        baseUrl
                        + 'api/addresses/'
                        + (addressParam.toLowerCase().startsWith('txch1') ? getXCHAddressFromTXCH(addressParam) : addressParam)
                        + '/cats'
                    ).then(x => x.json()).then(data => {
                        setCATBalances(data.results);
                    });
                } else {
                    if (GlobalSettings.coinFilter === 'cats') {
                        setCoinFilter('xch');
                        GlobalSettings.coinFilter = 'xch';
                    }
                }
            }).catch((err) => {
                setIsLoading(false)
                setErr(true)
            });
    }

    function onLoadMoreTo(event, cToken: string) {
        if (event) {
            event.preventDefault();
            setIsLoadingTransactions(true);
        }
        fetch(baseUrl
            + 'api/addresses/' + getXCHAddressFromTXCH(addressParam)
            + '/coins/?direction=to'
            + (cToken !== '' ? `&continuationToken=${cToken}` : '')
            + (coinFilter == 'cats' ? '&innerAddress=true' : '')
        )
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let newTransactions = [] as any;
                for (const key in data.results) {
                    const transaction = {
                        ...data.results[key],
                    };


                    if (transaction.fromAddress) {
                        transaction.fromAddress = getAddress(transaction.fromAddress)
                    }
                    transaction.toAddress = getAddress(transaction.toAddress)
                    transaction.fromAddressInner && (transaction.fromAddressInner = getAddress(transaction.fromAddressInner));
                    transaction.toAddressInner && (transaction.toAddressInner = getAddress(transaction.toAddressInner));

                    newTransactions.push(transaction);
                }
                if (data.continuationToken) {
                    setContinuationTokenTo(data.continuationToken);
                } else {
                    setHideButtonTo(true);
                }
                setIsLoadingTransactions(false);
                setToTransactions((transactions) => [...transactions, ...newTransactions]);

            });
    }

    function onLoadMoreFrom(event, cToken: string) {
        if (event) {
            event.preventDefault();
            setIsLoadingTransactions(true);
        }
        fetch(baseUrl
            + 'api/addresses/' + getXCHAddressFromTXCH(addressParam)
            + '/coins/?direction=from'
            + (cToken !== '' ? `&continuationToken=${cToken}` : '')
            + (coinFilter == 'cats' ? '&innerAddress=true' : '')
        )
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let newTransactions = [] as any;
                for (const key in data.results) {
                    const transaction = {
                        ...data.results[key],
                    };
                    transaction.toAddress = getAddress(transaction.toAddress)
                    transaction.fromAddressInner && (transaction.fromAddressInner = getAddress(transaction.fromAddressInner));
                    transaction.toAddressInner && (transaction.toAddressInner = getAddress(transaction.toAddressInner));

                    newTransactions.push(transaction);
                }
                if (data.continuationToken) {
                    setContinuationTokenFrom(data.continuationToken);
                } else {
                    setHideButtonFrom(true);
                }

                setIsLoadingTransactions(false);
                setFromTransactions((transactions) => [...transactions, ...newTransactions]);
            });
    }

    useEffect(() => {
        loadAddressData(null);
    }, []);

    useEffect(() => {
        if (isLoading) {
            return;
        }

        onLoadMoreTo(null, '');
        onLoadMoreFrom(null, '');
    }, [coinFilter, isLoading]);

    if (err) {
        return <NotFound />
    }

    let transactionJSX;
    if ((transactionsTo.length + transactionsFrom.length) !== 0) {
        transactionJSX = (
            <div style={{ paddingTop: 30 }}>
                <Tabs defaultActiveKey={coinFilter === 'cats' ? 'cats' : "incoming"}>
                    {
                        coinFilter === 'cats' && <Tab eventKey='cats' title={t`CAT Balances`}>
                            <div className='address-spacious cat-balances'>
                                <SpacePoolTable
                                    headerLine={true}
                                    rowLine={true}
                                    headers={[
                                        {
                                            label: 'CAT',
                                        },
                                        {
                                            label: t`Balance`,
                                        },
                                        {
                                            label: t`Transaction Count`,
                                        },
                                    ]}
                                    rows={catBalances.map(x =>
                                        [
                                            <CATProfile cat={x} />,
                                            NUM_FORMAT.format(x.balance / 1000),
                                            NUM_FORMAT.format(x.transactionCount),
                                        ]
                                    )}
                                />
                            </div>

                            <SpaceConstraintTable>
                                {catBalances.map((cat, i) => <SpaceConstraintCATListItem key={i} cat={cat} />)}
                            </SpaceConstraintTable>
                        </Tab>
                    }
                    <Tab eventKey="incoming" title={coinFilter == 'cats' ? t`Incoming CAT Coins` : t`Incoming Coins`}>
                        <div className={classNames("address-spacious", coinFilter === 'cats' && 'cat-mode')}>
                            <SpacePoolTable
                                headerLine={true}
                                rowLine={true}
                                headers={[
                                    coinFilter === 'cats' ? {
                                        label: 'CAT'
                                    } : undefined,
                                    {
                                        label: t`Coin Id`,
                                        extraClassName: 'monospace',
                                        flexible: true,
                                    },
                                    {
                                        label: t`Block`,
                                    },
                                    {
                                        label: coinFilter == 'cats' ? 'Amount' : [t`XCH`, t`Mojo`],
                                        extraClassName: 'text-align-end',
                                    },
                                    {
                                        label: t`From`,
                                        extraClassName: 'address-column monospace text-align-end',
                                    },
                                    {
                                        label: [t`Age`, t`Timestamp`],
                                        extraClassName: 'text-align-end',
                                    }
                                ]}
                                rows={transactionsTo
                                    .map((coin, i) => {
                                        const catCoin = coinFilter === 'cats' ? TryCoinAsCATCoin(coin) : undefined;
                                        const catBalance = catCoin ? catBalances.find(x => FORMAT_TAIL(x.tail) === FORMAT_TAIL(catCoin.tail)) : undefined;
                                        return [
                                            catBalance ? <CATProfile cat={catBalance} /> : undefined,
                                            <Link to={'/coins/' + coin.coinId.toLowerCase()}>{coin.coinId.toLowerCase()}</Link>,
                                            <Link to={'/blocks/' + coin.blockHeight}>{coin.blockHeight.toLocaleString()}</Link>,
                                            coinFilter == 'cats' ? NUM_FORMAT.format(coin.val / 1000) : XCHToggleValue(coin.val),
                                            coin.coinbase === true ? (
                                                t`Farming Reward`
                                            ) : (
                                                <Link to={'/addresses/' + (catCoin?.fromAddressInner || coin.fromAddress)}>
                                                    {catCoin?.fromAddressInner || coin.fromAddress}
                                                </Link>
                                            ),
                                            coin?.timestamp ? DateToggleValue(coin.timestamp) : ''

                                        ];
                                    })}
                            />
                        </div>
                        <SpaceConstraintTable>
                            {transactionsTo.map((coin, i) => <SpaceConstraintCoinListItem key={i} coin={coin} hideTo={true} />)}
                        </SpaceConstraintTable>

                        <div className="view-all">
                            <a href="/" onClick={e => onLoadMoreTo(e, continuationTokenTo)}>
                                <button disabled={isLoadingTransactions} hidden={hideButtonTo} className="primary">{t`Load More`}</button>
                            </a>
                        </div>
                    </Tab>
                    <Tab eventKey="outgoing" title={coinFilter == 'cats' ? t`Outgoing CAT Coins` : t`Outgoing Coins`}>
                        <div className={classNames("address-spacious", coinFilter === 'cats' && 'cat-mode')}>
                            <SpacePoolTable
                                headerLine={true}
                                rowLine={true}
                                headers={[
                                    coinFilter === 'cats' ? {
                                        label: 'CAT'
                                    } : undefined,
                                    {
                                        label: 'Coin Id',
                                        extraClassName: 'monospace',
                                        flexible: true,
                                    },
                                    {
                                        label: 'Block',
                                    },
                                    {
                                        label: coinFilter == 'cats' ? 'Amount' : [t`XCH`, t`Mojo`],
                                        extraClassName: 'text-align-end',
                                    },
                                    {
                                        label: 'To',
                                        extraClassName: 'address-column monospace text-align-end',
                                    },
                                    {
                                        label: [t`Age`, t`Timestamp`],
                                        extraClassName: 'text-align-end',
                                    }
                                ]}
                                rows={transactionsFrom
                                    .map((coin, index) => {
                                        const catCoin = coinFilter === 'cats' ? TryCoinAsCATCoin(coin) : undefined;
                                        const catBalance = catCoin ? catBalances.find(x => FORMAT_TAIL(x.tail) === FORMAT_TAIL(catCoin.tail)) : undefined;
                                        return [
                                            catBalance ? <CATProfile cat={catBalance} /> : undefined,
                                            <Link to={'/coins/' + coin.coinId.toLowerCase()}>{coin.coinId.toLowerCase()}</Link>,
                                            <Link to={'/blocks/' + coin.blockHeight}>{coin.blockHeight.toLocaleString()}</Link>,
                                            coinFilter == 'cats' ? NUM_FORMAT.format(coin.val / 1000) : XCHToggleValue(coin.val),
                                            coin.coinbase === true ? (
                                                t`Farming Reward`
                                            ) : (
                                                <Link className="txn-to-address" to={/addresses/ + (catCoin?.toAddressInner || coin.toAddress)}>
                                                    {catCoin?.toAddressInner || coin.toAddress}
                                                </Link>
                                            ),
                                            coin?.timestamp ? DateToggleValue(coin.timestamp) : ''

                                        ];
                                    })}
                            />
                        </div>
                        <SpaceConstraintTable>
                            {transactionsFrom.map((coin, i) => <SpaceConstraintCoinListItem key={i} coin={coin} hideFrom={true} />)}
                        </SpaceConstraintTable>
                        <div className="view-all">
                            <a href="/" onClick={e => onLoadMoreFrom(e, continuationTokenFrom)}>
                                <button id='from' disabled={isLoadingTransactions} hidden={hideButtonFrom} className="primary">{t`Load More`}</button>
                            </a>
                        </div>
                    </Tab>
                    {/* <Tab eventKey="blocks" title={t`Blocks Farmed`}>
                        <ContinuousTable headers={[
                            {
                                label: t`Height`,
                                flexible: true,
                            },
                            {
                                label: t`Txns`
                            },
                            {
                                label: t`Rewards`
                            },
                            {
                                label: [<strong>{t`Time Since`}</strong>, <strong>{t`Timestamp`}</strong>]
                            }
                        ]} />
                    </Tab> */}
                </Tabs>
            </div>
        );
    }

    const firstBlock = (coinFilter === 'cats' ? addressCatData?.firstBlockHeightCats : address?.firstBlockHeight) || 0;
    const lastBlock = (coinFilter === 'cats' ? addressCatData?.lastBlockHeightCats : address?.lastBlockHeight) || 0;
    const addCount = (coinFilter === 'cats' ? addressCatData?.transactionCountCats : address?.transactionCount) || 0;
    const remCount = (coinFilter === 'cats' ? addressCatData?.removalsCountCats : address?.removalsCount) || 0;

    return (
        address ? <div className="address-page">
            <h4 style={{ marginBottom: 0, overflowWrap: 'anywhere' }}><b>Wallet Address</b> <span className='monospace'>{address?.address || 'Unknown'}<CopyIcon text={address?.address || ''} /></span></h4>
            <div style={{ marginBottom: '24px' }}>{(address?.address && knownAddresses.get(address?.address)) ? `Known Address - ${knownAddresses.get(address?.address)}` : ''}</div>

            {
                [
                    [addressCatData ? t`Scope` : '', <div>
                        {
                            !address.innerAddress && <label className='coin-filter-option'>
                                {addressCatData && <input name='coin_filter' type='radio' value='xch' checked={coinFilter == 'xch'} onChange={handleCoinFilterChanged} />}
                                <div className={classNames('address-badge', 'xch', coinFilter == 'xch' ? 'selected' : '')} title={t`This address contains XCH transactions.`}>XCH</div>
                            </label>
                        }
                        {
                            addressCatData && <label className='coin-filter-option'>
                                <input name='coin_filter' type='radio' value='cats' checked={coinFilter == 'cats'} onChange={handleCoinFilterChanged} />
                                <div className={classNames('address-badge', 'cat', coinFilter == 'cats' ? 'selected' : '')} title={t`This address contains CAT transactions.`}>CAT</div>
                            </label>
                        }
                        {
                            address.innerAddress && <div className={classNames('address-badge', 'outer')} title={t`This is an Outer Address of a smart coin transaction. Unless you are certain in what you are doing, this address should NOT be used directly in transactions. See Inner Address below.`}>OUTER ADDRESS</div>
                        }
                    </div>],
                    address.innerAddress ? ['Inner Address', <Link to={`/addresses/${address.innerAddress}`}><div className='monospace'>{address.innerAddress}</div></Link>] : undefined,
                    ['Puzzle Hash', address?.address ? <div className='monospace'>{addressToPuzzle(address.address, true)}<CopyIcon text={addressToPuzzle(address.address, true)} /></div> : 'Unknown'],
                    coinFilter === 'xch' ? ['Balance', <div>{formatXCH(address?.balance || 0)}<AddressWarningIcon /></div>] : undefined,
                    ['First Block', <Link to={"/blocks/" + firstBlock}>{new Intl.NumberFormat().format(firstBlock)}</Link>],
                    ['Last Block', <Link to={"/blocks/" + lastBlock}>{new Intl.NumberFormat().format(lastBlock)}</Link>],
                    ['Addition Txs Count', addCount],
                    ['Removals Txs Count', remCount],
                    coinFilter === 'cats' ? ['CATs', catBalances?.length || 0] : undefined,
                    coinFilter === 'xch' ? ['Reward Txs Count', address?.rewardCount || 0] : undefined,
                    coinFilter === 'xch' ? ['Est XCH Volume', formatXCH((address?.totalRemovedMojo || 0) + (address?.totalAddedMojo || 0) + (address?.totalRewardMojo || 0))] : undefined,

                ].filter(x => x?.length).map((row, i) =>
                    <div key={i} style={{ marginBottom: '16px' }}>
                        <div><b>{row![0]}</b></div>
                        <div style={{ marginRight: '12px', overflow: 'hidden', overflowWrap: 'anywhere' }}> {row![1]}</div>
                    </div>
                )}

            {transactionJSX}
        </div> : <></>
    );
}

export default AddressPage;
