import { t } from '@lingui/macro';
import QRCode from 'qrcode.react';
import { useEffect, useState } from 'react';
import { Button, Modal, Tab, Tabs } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';
import Edit from '../../components/edit';
import EditComplicated from '../../components/EditComplicated';
import { SpacePoolTable } from '../../components/spacepool-table/spacepool-table';
import { ToggleSwitch } from '../../components/toggle-switch/toggle-switch';
import { baseUrl } from '../../shared';
import { isEmbedMode } from '../../utils/navigator-util';
import './account-page.scss';

function AccountPage() {
    const showQRCodeLabel = t`Show Mobile QR code`;
    const hideQRCodeLabel = t`Hide Mobile QR code`;
    const [qrVisibilityLabel, setQrVisibilityLabel] = useState(showQRCodeLabel);
    const [addresses, setAddresses] = useState([]) as any;
    const [val, setVal] = useState([]) as any;
    const [showAddAddress, setShowAddAddress] = useState(false) as any;
    const [showDeleteAddress, setShowDeleteAddress] = useState(false) as any;
    const [addressToDelete, setAddressToDelete] = useState(false) as any;
    const [addressToDeleteIndex, setAddressToDeleteIndex] = useState(false) as any;
    const [userSettings, setUserSettings] = useState(false) as any;
    const [developerKey, setDeveloperKey] = useState(false) as any;
    const [showKey, setShowKey] = useState(false) as any;

    const [discordId, setDiscordId] = useState('') as any;
    const [webhook, setWebhook] = useState('') as any;
    const [discordNotifications, setDiscordNotifications] = useState() as any;
    const [webhookNotifications, setWebhookNotifications] = useState() as any;
    const [pushNotifications, setPushNotifications] = useState() as any;
    const [addressBalanceChanged, setAddressBalanceChanged] = useState() as any;


    const [tempDiscordId, setTempDiscordId] = useState('') as any;
    const [tempWebhook, setTempWebhook] = useState('') as any;
    const [tempDiscordNotifications, setTempDiscordNotifications] = useState() as any;
    const [tempWebhookNotifications, setTempWebhookNotifications] = useState() as any;
    const [tempPushNotifications, setTempPushNotifications] = useState() as any;
    const [tempAddressBalanceChanged, setTempAddressBalanceChanged] = useState() as any;
    const [error, setError] = useState(false) as any;
    const [addError, setAddError] = useState(false) as any;

    const [tabKey, setTabKey] = useState<string>('addresses');
    const [notificationSettingsChanged, setNotificationSettingsChanged] = useState(false);

    let history = useHistory();

    const onQRVisibilityToggle = () => {
        if (qrVisibilityLabel === showQRCodeLabel) {
            setQrVisibilityLabel(hideQRCodeLabel);
        } else {
            setQrVisibilityLabel(showQRCodeLabel);
        }
    };

    useEffect(() => {
        // console.log(localStorage.getItem('loginToken'))
        if (localStorage.getItem('loginToken')) {
            let accountId = localStorage.getItem('accountId')
            // console.log(accountId)
            let url = baseUrl + `api/accounts/${accountId}`
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Auth-Token': localStorage.getItem('loginToken') || ''
                }
            })
                .then((response) => {
                    if (response.status === 403) {
                        localStorage.removeItem('loginToken');
                        history.push('/login');
                    }
                    if (response.ok) {
                        return response.json();
                    }

                    throw ('Error loading account info');
                }).then(data => {
                    setUserSettings(data);
                    setDiscordId(data.discordId);
                    setWebhook(data.discordWebhook);
                    setDiscordNotifications(data.notificationSettings.mediumSettings.enableDiscord);
                    setWebhookNotifications(data.notificationSettings.mediumSettings.enableDiscordWebhook);
                    setPushNotifications(data.notificationSettings.mediumSettings.enablePush);
                    setAddressBalanceChanged(data.notificationSettings.typeSettings.addressBalanceChanged)
                    setUserSettings(data);
                    //extra vars to help keep track of original values when edited.
                    setTempDiscordId(data.discordId);
                    setTempWebhook(data.discordWebhook);
                    setTempDiscordNotifications(data.notificationSettings.mediumSettings.enableDiscord);
                    setTempWebhookNotifications(data.notificationSettings.mediumSettings.enableDiscordWebhook);
                    setTempPushNotifications(data.notificationSettings.mediumSettings.enablePush)
                    setTempAddressBalanceChanged(data.notificationSettings.typeSettings.addressBalanceChanged)

                    if (data.hasDeveloperKey) {
                        const url = baseUrl + `api/accounts/${localStorage.getItem('accountId')}/developer`;
                        return fetch(url, {
                            headers: {
                                'Content-Type': 'application/json',
                                'Auth-Token': localStorage.getItem('loginToken') || '',
                            },

                        })
                            .then((response) => {
                                if (response.status === 403) {
                                    history.push('/login')
                                }
                                if (response.ok) {
                                    return response.json();
                                }

                                throw Error("error with response:" + response);
                            })
                            .then(data => {
                                setDeveloperKey(data.developerKey);
                                //return data.developerKey
                            })
                    }
                }
                ).catch((e) => {
                    console.log(e);
                })
            //address info

            //get addresses for this account
            url = baseUrl + `api/accounts/${accountId}/addresses`;
            fetch(url, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Auth-Token': localStorage.getItem('loginToken') || ''
                }
            })
                .then((response) => {
                    if (response.status === 403) {
                        history.push('/login');
                    }
                    if (response.ok) {
                        return response.json();
                    }
                    throw ('Error accessing account page');
                }
                )
                .then(data => {
                    setAddresses(data.results);
                });

            //notification settings for this account
        } else {
            localStorage.removeItem('loginToken');
            history.push('/login');
        }

    }, []);

    function setValue(e) {
        let caller = e.target || e.srcElement;
        caller.value = e.target.value
    }
    function save(alias, address) {
        if (alias && address && localStorage.getItem('accountId')) {
            const url = baseUrl + `api/accounts/${localStorage.getItem('accountId')}/addresses/${address}`
            return fetch(url, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'Auth-Token': localStorage.getItem('loginToken') || '',

                },
                body: JSON.stringify({
                    'alias': alias
                })
            })
                .then((response) => {
                    if (response.status === 403) {
                        history.push('/login')
                    }
                    if (response.ok) {
                        return response.json();
                    }

                    throw Error("error with response:" + response);
                })
                .then(data => {
                    return data
                }).catch((e) => {
                    throw e
                })
        }
    }

    function addAddress(event) {
        event.preventDefault();
        let alias = event.target.alias.value;
        let address = event.target.address.value;

        if (alias && address) {
            //TODO filter bad data? 
            save(alias, address)?.then(() => {
                const newAddress = {
                    alias: alias,
                    address: address
                }
                setAddresses([...addresses, newAddress])
                setShowAddAddress(false);
                setAddError('')
            }).catch((e) => {
                setAddError('Unable to save, please check provided values. Alias must be 3+ alphanumeric characters with no spaces.')
            });
        }
    }

    function deleteAddress(event) {
        // console.log('delete', addressToDelete)
        event.preventDefault();
        let address = addressToDelete;
        if (address && localStorage.getItem('accountId')) {
            const url = baseUrl + `api/accounts/${localStorage.getItem('accountId')}/addresses/${address}`
            return fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Auth-Token': localStorage.getItem('loginToken') || '',
                }
            })
                .then((response) => {
                    // console.log('response:', response)
                    if (response.status === 403) {
                        history.push('/login')
                    }
                    if (response.ok) {
                        setAddresses(addresses.filter(a => a.address != address));
                        setShowDeleteAddress(false);
                        return response.json();
                    }

                    throw Error("error with response:" + response);
                })
                .then(data => {
                    setAddresses(addresses.filter(a => a.address != address));
                    return data
                }).catch((e) => {
                    throw e
                })
        }
        // if (address){
        //TODO filter bad data? 
        /*     save(alias, address)?.then(() => {
                 const newAddress = {
                     alias: alias,
                     address: address
                 }
                 setAddresses([...addresses, newAddress])
             })
         }*/
        setShowDeleteAddress(false);
    }

    function hideAddAddress(event) {
        setShowAddAddress(false);
    }

    function requestAPIKey(event) {

        ///api/accounts/{accountId}/developer

        const url = baseUrl + `api/accounts/${localStorage.getItem('accountId')}/developer`;
        return fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': localStorage.getItem('loginToken') || '',

            },
            body: JSON.stringify({
                'accountId': localStorage.getItem('accountId')
            })
        })
            .then((response) => {
                if (response.status === 403) {
                    history.push('/login')
                }
                if (response.ok) {
                    return response.json();
                }

                throw Error("error with response:" + response);
            })
            .then(data => {
                // console.log(data)
                setDeveloperKey(data.developerKey);
                return data.developerKey
            }).catch((e) => {
                throw e
            })
    }

    function toggleShowKey() {
        setShowKey(!showKey)
    }

    function updateUser() {
        const url = baseUrl + `api/accounts/${localStorage.getItem('accountId')}`;
        return fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': localStorage.getItem('loginToken') || '',

            },
            body: JSON.stringify({
                discordId: 'discordId',
                discordWebhook: webhook,
                "notificationSettings": {
                    "typeSettings": {
                        "addressBalanceChanged": addressBalanceChanged
                    },
                    "mediumSettings": {
                        "enablePush": pushNotifications,
                        "enableDiscord": discordNotifications,
                        "enableDiscordWebhook": webhookNotifications
                    }
                }
            })
        })
            .then((response) => {
                if (response.status === 403) {
                    history.push('/login')
                }
                if (response.ok) {
                    // console.log(response)
                    return response.json();
                }

                throw Error("error with response:" + response);
            })
            .then(data => {
                // console.log('data', data)
                return data
                //setDeveloperKey(data.developerKey);
                //return data.developerKey
            }).catch((e) => {
                throw e
            })
    }

    function saveTempSettings() {
        const url = baseUrl + `api/accounts/${localStorage.getItem('accountId')}`;
        return fetch(url, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Auth-Token': localStorage.getItem('loginToken') || '',

            },
            body: JSON.stringify({
                ...(tempDiscordId ? { discordId: tempDiscordId } : {}),
                ...(tempWebhook ? { discordWebhook: tempWebhook } : {}),
                "notificationSettings": {
                    "typeSettings": {
                        "addressBalanceChanged": tempAddressBalanceChanged
                    },
                    "mediumSettings": {
                        "enablePush": tempPushNotifications,
                        "enableDiscord": tempDiscordNotifications,
                        "enableDiscordWebhook": tempWebhookNotifications
                    }
                }
            })
        }).then((response) => {
            if (response.status === 403) {
                history.push('/login')
            }
            if (response.ok) {
                setNotificationSettingsChanged(false);
                setDiscordId(tempDiscordId);
                setWebhook(tempWebhook);
                setDiscordNotifications(tempDiscordNotifications);
                setWebhookNotifications(tempWebhookNotifications);
                setPushNotifications(tempPushNotifications);
                setAddressBalanceChanged(tempAddressBalanceChanged);
                setError('');
                return response.json();
            }

            throw Error("error with response:" + response);
        }).then(data => {
            return data
        }).catch((e) => {
            setError('Error saving settings. Check provided values.')
            throw e
        })

    }

    return <div className='page'>
        {isEmbedMode() ? <></> : <>
            <div style={{ color: '#03b000', cursor: 'pointer' }} onClick={onQRVisibilityToggle}>{qrVisibilityLabel}</div>
            <div style={{ marginBottom: -10, background: 'white', width: 150, padding: 10, display: qrVisibilityLabel === showQRCodeLabel ? 'none' : 'block' }}>
                <QRCode
                    value={`https://${document.location.host}/account?loginToken=${localStorage.getItem('loginToken')}&accountId=${localStorage.getItem('accountId')}&expirationDate=${localStorage.getItem('expirationDate')}`}
                    size={128}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                    level={"L"}
                    includeMargin={false}
                    renderAs={"canvas"}
                    imageSettings={{
                        src: "/favicon.ico",
                        x: null,
                        y: null,
                        height: 24,
                        width: 24,
                        excavate: false,
                    }}
                />
            </div>
            <br /></>}
        {userSettings.publicKey ? <strong><p style={{ overflowWrap: 'anywhere' }}>Public Key {userSettings.publicKey}</p></strong> : ''}

        <Tabs defaultActiveKey="addresses" onSelect={(value) => { setTabKey(value || 'addresses') }}>
            <Tab eventKey='addresses' title='Addresses'>
                <br />
                <p>Keep track of important addresses. With notifications enabled, you will receive an update when a balance is changed</p>
                <SpacePoolTable
                    headerLine={true}
                    headers={[
                        {
                            label: t`Alias`,
                            extraClassName: 'alias-column',
                            compact: true,
                        },
                        {
                            label: t`Address`,
                            extraClassName: 'address-column',
                            compact: true,
                        },
                        {
                            label: <div style={{ opacity: 0 }}>X</div>,
                        }
                    ]}
                    rows={addresses.map((address, i) => {
                        //setVal(address.alias)
                        return [
                            <Edit value={address.alias} save={save} address={address.address} />,
                            <Link to={'/addresses/' + address.address}><div style={{ textOverflow: 'ellipsis', overflow: 'hidden', }}>{address.address}</div></Link>,
                            <Button size="sm" onClick={(event) => {
                                setAddressToDelete(address.address);
                                //setAddressToDelete(addresses.indexOf(address));
                                setShowDeleteAddress(true)
                            }
                            }>X</Button>,
                        ];
                    })}

                />

                {showDeleteAddress ?
                    <Modal className='add-address-modal' show={showDeleteAddress} onHide={() => { setShowDeleteAddress(false) }}>
                        <Modal.Header>
                            <Modal.Title>
                                Delete an address
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form method="POST" onSubmit={deleteAddress}>
                                <div>Are you sure you want to stop monitoring this address?</div>
                                <br />
                                <button className='primary'>Delete</button>
                            </form>
                        </Modal.Body>
                    </Modal>
                    : ' '}
                <button onClick={() => { setShowAddAddress(true) }} className='primary'>Add an address</button>


                {showAddAddress ? <Modal className='add-address-modal' show={showAddAddress} onHide={hideAddAddress}>
                    <Modal.Header>
                        <Modal.Title>
                            Monitor an address
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form method="POST" onSubmit={addAddress}>
                            <div><label htmlFor='alias'>Alias: </label></div>
                            <div><input id='alias' placeholder='Coldwallet'></input></div>
                            <br />
                            <div><label htmlFor='address'>Address: </label></div>
                            <div><input id='address' size={50} placeholder='xch11ngfrtzx42hqdvrv3x8qwm80qhjf4qrx6ngjpd4hmrgsvx0mschswvkcnd'></input></div>
                            <br />

                            <button className='primary'>Submit</button>
                        </form>
                        {addError ? <div style={{ 'color': 'red' }}>{addError}</div> : ''}
                    </Modal.Body>
                </Modal> : ''}
            </Tab>
            {/*<Tab eventKey='deveoperAPI' title='developer API'>
        {userSettings ? <div>
            <h4>Developer API Key</h4>
            {developerKey ? <span>{showKey ? <span>{developerKey}</span> : '***************************************'} 
            
            <strong onClick={toggleShowKey}>{showKey ? <span> 🔎 hide</span> : '🔎 show'}</strong></span>: 
                <button className='primary' onClick={requestAPIKey}>Request a Key</button>}
        </div> : ''}
        </Tab>
        */}
            <Tab eventKey='notifications' title='Notifications'>
                {userSettings ? <div>
                    <h4>Notifications</h4>
                    <div>Discord ID: <EditComplicated style={{ border: tempDiscordId !== discordId ? '1px solid blue' : '' }} key={tempDiscordId || ''} value={tempDiscordId} placeholder={'(example) 501572278183233186'} save={(v) => { setNotificationSettingsChanged(true); setTempDiscordId(v) }} isTempEdit={true} onChange={(v) => { setNotificationSettingsChanged(true) }} onBlur={(v) => setTempDiscordId(v)} /></div>
                    <div><a target='_blank' href="https://blog.pool.space/enabling-health-notifications-on-discord-for-your-chia-farm-in-space-pool-da9bb1368260">How do I get it?</a></div>
                    <br />
                    <div>Discord Webhook: <EditComplicated style={{ border: tempWebhook !== webhook ? '1px solid blue' : '' }} key={tempWebhook || ''} value={tempWebhook} placeholder={'(example) https://discord.com/api/webhooks/760087623fdfgdMDyHQxFrRTvldfgdfvdfvdoOuOWxBUUiu00'} save={(v) => { setNotificationSettingsChanged(true); setTempWebhook(v) }} isTempEdit={true} onChange={() => { setNotificationSettingsChanged(true) }} onBlur={(v) => setTempWebhook(v)} size={50} /></div>
                    <div><a target='_blank' href="https://blog.pool.space/enabling-webhook-notifications-on-discord-for-your-chia-farm-in-space-pool-7061e3f8cfe7">How do I get it?</a></div>
                    <br />
                    <div className='notification-medium-settings'>
                        <SpacePoolTable
                            headers={[
                                {
                                    label: <span>Devices to Notify</span>,
                                },
                            ]}
                            rows={[

                                [
                                    <div style={{ width: 'fit-content' }}><ToggleSwitch disabled={!tempDiscordId} label="Discord" forceState={tempDiscordNotifications} onChange={(event) => { setNotificationSettingsChanged(true); setTempDiscordNotifications(!tempDiscordNotifications) }} /></div>,
                                ],
                                [
                                    <div style={{ width: 'fit-content' }}><ToggleSwitch disabled={!tempWebhook} label="Discord Webhook" forceState={tempWebhookNotifications} onChange={() => { setNotificationSettingsChanged(true); setTempWebhookNotifications(!tempWebhookNotifications) }} /></div>,
                                ],
                                [
                                    <div style={{ width: 'fit-content' }}><ToggleSwitch label="Mobile Devices Push" forceState={tempPushNotifications} onChange={() => { setNotificationSettingsChanged(true); setTempPushNotifications(!tempPushNotifications) }} /></div>,
                                ],
                                [
                                    'Notification Types:'
                                ],
                                [
                                    <div style={{ width: 'fit-content' }}><ToggleSwitch label="On Balance Change" forceState={tempAddressBalanceChanged} onChange={() => { setNotificationSettingsChanged(true); setTempAddressBalanceChanged(!tempAddressBalanceChanged) }} /></div>,
                                ],
                                [
                                    notificationSettingsChanged ? <div><button onClick={() => {
                                        setTempDiscordNotifications(discordNotifications);
                                        setTempWebhookNotifications(webhookNotifications);
                                        setTempPushNotifications(pushNotifications);
                                        setTempAddressBalanceChanged(addressBalanceChanged);
                                        const d = document.getElementById('discordId');

                                        setTempDiscordId(discordId)
                                        setTempWebhook(webhook)
                                        setNotificationSettingsChanged(false);
                                    }} className=''>Cancel</button> <button className='primary' onClick={saveTempSettings}>Save Changes</button></div> : ''
                                ], [
                                    error ? <div style={{ 'color': 'red' }}>{error}</div> : ''
                                ]
                            ]}
                        />
                    </div>

                </div> : ''}
            </Tab>
        </Tabs>
    </div>

}

export default AccountPage;