import { Trans } from '@lingui/macro';
import { handleMobileAppNavigation } from '../../utils/navigator-util';
import './about-page.scss';

export const AboutPage = () => {
    return (
        <div className='about-page'>
            <h4><Trans>About Us</Trans></h4>
            <div className='about-page-content'>
                <p>
                    <Trans>Welcome to Space Explorer!</Trans>
                </p>
                <p>
                    <Trans>We offer a service to explore the Chia Cryptocurrency blockchain through tables, charts, and analytics. All data is provided as-is and we do not guarantee the accuracy, completeness, or consistency of any data contained on the website.</Trans>
                </p>
                <p>
                    <Trans>Space Explorer is owned by Space Pool, LLC. Our team brings our existing Chia blockchain expertise to provide this explorer free for the community to use.</Trans>
                </p>
                <p>
                    <Trans>To reach out to the team, find us on <a onClick={(ev: any) => handleMobileAppNavigation(ev)} href='https://pool.space/discord' rel="noreferrer" target='_blank'>Discord</a> or email us at <a href='mailto:info@pool.space'>info@pool.space</a></Trans>
                </p>
            </div>
        </div>
    );
};