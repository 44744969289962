import classNames from 'classnames';
import { useRef, useState } from 'react';
import { ToggleHeader } from '../toggle-header/toggle-header';
import './spacepool-table.scss';

export interface ISpacePoolTableProps {
    headerLine?: boolean;
    headers: {
        compact?: boolean;
        extraClassName?: string;
        flexible?: boolean;
        label: any;
    }[];
    rowLine?: boolean;
    rows: any[][];
}

export const SpacePoolTableNoClass = (props: ISpacePoolTableProps) => {
    const [, forceUpdate] = useState({});
    const headerStatesRef = useRef(new Set<number>());
    const headerStates = headerStatesRef.current;

    return (
        <div className={classNames('spacepool-table', props.headerLine && 'header-line', props.rowLine && 'row-line')}>
            {
                props.headers.map((header, i) =>
                    <div key={i} className={classNames('spacepool-table-column', header.extraClassName, header.flexible && 'flexible', header.compact && 'compact')}>
                        {
                            <>
                                <div className='spacepool-table-column-header'>
                                    {
                                        Array.isArray(header.label)
                                            ? <ToggleHeader label={header.label[0]} altLabel={header.label[1]} onClick={isAlt => {
                                                isAlt ? headerStates.add(i) : headerStates.delete(i);
                                                forceUpdate({});
                                            }} />
                                            : header.label
                                    }
                                </div>
                                {
                                    props.rows.map(x => x[i]).map((value, j) =>
                                        <div key={j} className='spacepool-table-column-value-no-class' style={{ animationDelay: ((33 * j) % 1000) + 'ms' }}>
                                            {
                                                Array.isArray(value) ? (headerStates.has(i) ? value[1] : value[0]) : value
                                            }
                                        </div>
                                    )
                                }
                            </>
                        }
                    </div>
                )
            }
        </div>
    );
};