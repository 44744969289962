import { t } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { baseUrl } from '../../shared';
import { ChiaPriceTicker } from '../chia-price-ticker/chia-price-ticker';
import { LoadingFade } from '../loading-fade/loading-fade';
import { StatBox } from '../stat-box/stat-box';
import './stats.scss';

const MIN_FEE_PER_COST = 5;

function percentColors(value: number) {
    if (value > 0.9) return 'red';
    if (value > 0.6) return 'orange';
    if (value > 0.3) return 'darkgoldenrod';
    return '';
}

function feePerCostFormat(feePerCost: number, mempoolPercent: number) {
    if (mempoolPercent < 0.1 && feePerCost < MIN_FEE_PER_COST) return { label: t`None`, color: '' };
    if (feePerCost >= (MIN_FEE_PER_COST * 2)) return { label: t`High`, color: 'red' };
    if (feePerCost >= MIN_FEE_PER_COST) return { label: t`Medium`, color: 'orange' };
    return { label: t`Low`, color: 'darkgoldenrod' };
}

function Stats() {
    const [isLoading, setIsLoading] = useState(true);
    const [stats, setStats] = useState() as any;

    function onLoad(event) {
        const url = baseUrl + 'api/stats'
        fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw Error("error with response:" + response);
            })
            .then((data) => {
                setIsLoading(false);
                setStats(data);
            }
            ).catch((err) => {
                //Change this back
                setIsLoading(false)
                //setErr(true)
                console.log(err);
            });
    };

    let intervalID;
    useEffect(() => {
        onLoad(null)
        intervalID = setInterval(() => {
            if (!document.hidden) {
                onLoad(null)
            }
        }, 10000);

        return () => clearInterval(intervalID);
    }, []);

    const mempoolCostInGs = ((stats?.mempoolCost * stats?.mempoolSize) || 0) / (10 ** 9);
    const blockCostInGs = (stats?.blockCost || 0) / (10 ** 9);
    const mempoolPercent = mempoolCostInGs / 11 / 10;
    const blockPercent = blockCostInGs / 11;
    const feePerCost = stats?.fees / stats?.blockCost;
    const feeFormat = feePerCostFormat(feePerCost, mempoolPercent);

    return (<div>
        <div className="stats">
            <StatBox
                label={'Peak Block'}
                value={
                    <LoadingFade isLoaded={!isLoading}>
                        {stats?.unconfirmedBlockHeight?.toLocaleString()}
                    </LoadingFade>
                }
            />
            <StatBox
                label={'Chia Netspace'}
                value={
                    <LoadingFade isLoaded={!isLoading}>{
                        stats ? ((stats?.netspace / 1024 / 1024) > 1 ? (stats?.netspace / 1024 / 1024).toFixed(2) + ' EiB' :
                            stats?.netspace / 1024 > 1 ? (stats?.netspace / 1024).toFixed(2) + ' PiB' :
                                (stats?.netspace).toFixed(2) + ' TiB') : 'Unknown'
                    }</LoadingFade>
                }
            />
            <StatBox
                label={'Avg Fees'}
                value={<span title={`${feePerCost.toFixed(2)} Mojo Per Cost`} style={{ color: feeFormat.color, cursor: 'help' }}><LoadingFade isLoaded={!isLoading}>{feeFormat.label}</LoadingFade></span>}
            />
            <StatBox
                label={'Chia Price'}
                value={<ChiaPriceTicker twoLines={true} />}
            />
            <StatBox
                label={'Mempool Size'}
                value={
                    <LoadingFade isLoaded={!isLoading}>
                        {typeof stats?.mempoolSize === 'number' ? (stats.mempoolSize + ' Txs') : ''}
                    </LoadingFade>
                }
            />
            <StatBox
                label={'Mempool Capacity'}
                value={
                    <LoadingFade isLoaded={!isLoading}>
                        <span title={`Cost: ${mempoolCostInGs.toFixed(2)} G`} style={{ color: percentColors(mempoolPercent), cursor: 'help' }}>{new Intl.NumberFormat(undefined, { style: 'percent', maximumFractionDigits: 2 }).format(mempoolPercent)}</span>
                    </LoadingFade>
                }
            />
            <StatBox
                label={'Recent Block Capacity'}
                value={
                    <LoadingFade isLoaded={!isLoading}>
                        <span title={`Cost: ${blockCostInGs.toFixed(2)} G`} style={{ color: percentColors(blockPercent), cursor: 'help' }}>{new Intl.NumberFormat(undefined, { style: 'percent', maximumFractionDigits: 2 }).format(blockPercent)}</span>
                    </LoadingFade>
                }
            />
        </div>

    </div>)
}

export default Stats;