import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import moment from 'moment';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Area, AreaChart, CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from '../../index.reducers';
import { baseUrl } from '../../shared'

export const FeePerCostChart = (props : any) => {
    const darkMode = useSelector((state: RootState) => state.topLevel.darkMode);
    let data: { sortKey: number; date: string, data: number}[] = [];
    if (props.stats) {
        let i = 0;
        for (const entry of props.stats) {
            data.push({
                sortKey: entry.timestamp,
                date: entry.blockHeight,
                data: Number((entry.feesAverage / entry.costAverage).toFixed(2))
            });
        }
        data = data.sort((a, b) => a.sortKey - b.sortKey).reverse();
    }

    return (
        <>
        <ResponsiveContainer width="100%" height="100%" >
            <LineChart
                width={500}
                height={400}
                data={data}
                margin={{
                    top: 20,
                    right: 20,
                    bottom: 20,
                    left: 20,
                }}
                >
                    <XAxis dataKey="date" minTickGap={80} tickSize={16} tickFormatter={(block) => block} />
                    <YAxis  unit=' mojo'/>
                    <CartesianGrid strokeDasharray="3 3" strokeOpacity="0.3" />
                    <Tooltip contentStyle={{ backgroundColor: darkMode ? '#000' : '#FFF', opacity: '0.7' }} />
                    <Legend />
                    <Line type="monotone" name={t`Fee per Cost`} unit=" Mojo" dataKey="data" stroke="#03c000"  />
                </LineChart>
        </ResponsiveContainer>
        </>
    );
}
