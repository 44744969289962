import { useState } from "react";
import { useHistory } from "react-router";
import Copy from "../../components/Copy";
import ToolTipIcon from "../../components/tool-tip-icon";
import LoginImg from '../../images/login.png';
import SPLoginImg from '../../images/sp-login.png';
import { baseUrl } from "../../shared";
import './register-page.scss';
import SignMessage from "./sign-message";

function RegisterPage() {
    const [registering, setRegistering] = useState(false);
    const [spacePoolLogin, setSpacePoolLogin] = useState(false);
    const [loggingIn, setLoggingIn] = useState(false);
    const [registrationCode, setRegistrationCode] = useState();
    const [loginCode, setLoginCode] = useState();
    const [fp, setFp] = useState() as any; //fingerprint
    const [keyPath, setKeyPath] = useState('m/12381/8444/0/0');
    const [sigPk, setSigPk] = useState(); //public key
    const [error, setError] = useState('');
    const [step, setStep] = useState(0);

    let history = useHistory();

    function generateSignMessage(event) {

        event.preventDefault();
        if (event.target.fp.value) {
            setError('')
            setFp(event.target.fp.value)

            fetch(baseUrl + 'api/accounts/register/code', { method: 'POST' })
                .then(response => response.json())
                .then(data => {
                    setStep(1);
                    setRegistrationCode(data.registrationCode);
                });
        } else {
            setError('Error, please check submitted values.')
        }
    }
    function generateLoginSignMessage(event) {

        event.preventDefault();
        if (fp) {
            if (event.target.sigPk.value) {
                setSigPk(event.target.sigPk.value);
                setError('')

                fetch(baseUrl + 'api/accounts/Login/code', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        publicKey: event.target.sigPk.value.trim()
                    })
                })
                    .then(response => {
                        if (response.ok) {
                            return response.json()
                        }
                        throw error;
                    })
                    .then(data => {
                        setLoginCode(data.loginCode);
                        setStep(3);
                    }).catch((err) => {
                        setError('Error generating login code. Please double check provided values.');
                    })
            } else {
                setError('Please provide your public key to generate the command.');
            }
        }
        else {
            setError('Please provide a fingerprint to generate the command.');
        }
    }
    function generateToken(event) {

        event.preventDefault();
        if (event.target.sig.value, event.target.sigPk.value) {

            fetch(baseUrl + 'api/accounts/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    publicKey: event.target.sigPk.value,
                    sig: event.target.sig.value,
                    registrationCode: registrationCode
                })
            })
                .then((response) => {
                    // console.log(response.status)
                    if (response.status == 409) {
                        let errMsg = 'Account already exists. Try logging in instead.'
                        setError(errMsg)
                        throw errMsg;
                    }
                    if (response.ok) {
                        return response.json()
                    }
                    let errMsg = 'error creating account. Please double check provided values or try again later.'
                    setError(errMsg)
                    throw errMsg;
                })

                .then(data => {
                    localStorage.setItem('loginToken', data.loginToken);
                    localStorage.setItem('accountId', data.accountId);
                    localStorage.setItem('expirationDate', data.expirationDate);
                    history.push("/account")
                }).catch((err) => {
                    if (err) {
                        setError(err)
                    }
                    else {
                        //not sure how to properly hit this section
                        //setError('error creating account. Please double check provided values or try again later.');
                    }
                });
        } else {
            setError('Error, please check submitted values.');
        }
    }
    function generateLoginToken(event) {

        event.preventDefault();
        if (event.target.sig.value && sigPk) {

            fetch(baseUrl + 'api/accounts/login/verify', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    publicKey: sigPk,
                    sig: event.target.sig.value,
                    loginCode: loginCode
                })
            })
                .then((response) => {
                    if (response.ok) {
                        return response.json()
                    }
                    let errMsg = 'error creating account'
                    setError(errMsg)
                    throw errMsg;
                })

                .then(data => {
                    localStorage.setItem('loginToken', data.loginToken);
                    localStorage.setItem('accountId', data.accountId);
                    localStorage.setItem('expirationDate', data.expirationDate);
                    history.push("/account")
                }).catch((err) => {
                    setError('error logging in. Please double check provided values or try again later.');
                });
        } else {
            setError('Error, please check submitted values.')
        }
    }

    function step1(event) {
        event.preventDefault();
        setError('')
        if (event.target.fp.value) {
            setFp(event.target.fp.value)
            setStep(2)
        } else {
            setError('Please provide a keypath.')
        }
    }
    const intro = <div><p>To login you will sign a message with <code>chia keys sign</code> <ToolTipIcon explanation="This allows us to prove your ownership of a public key. We will generate the needed command."
        explanation2={<p>You will need access to the Chia CLI. Find instructions <a target='_blank' href={"https://github.com/Chia-Network/chia-blockchain/wiki/CLI-Commands-Reference#locate-the-chia-binary-executable"}>here.</a></p>} />. </p>
        <p></p>
        <p>Using <code>chia keys show<Copy value={'chia keys show'} /></code> provide us with:</p>
        <div><strong>Fingerprint:</strong> <ToolTipIcon explanation="The fingerprint is a number representing your wallet. This allows you to choose which wallet you want to associate with your new account. If you only have a single Chia wallet, use that fingerprint." /></div>
    </div>
    return (
        registering ? <div className='page'>
            {step == 0 ?
                <div>

                    {intro}
                    <form method='POST' onSubmit={generateSignMessage}>
                        <div><input type='text' id='fp' size={20} placeholder='(example) 1746461948'></input></div>
                        <br />
                        <br />

                        <div><button className='primary'>Get Registration Code</button></div>
                        <br />
                    </form>
                </div>
                : ''}
            {registrationCode && fp ?
                <div>
                    <p>Next, issue this command in the Chia CLI:</p>

                    <SignMessage id='register' fp={fp} keyPath={keyPath} code={registrationCode}></SignMessage>

                    {/*<div>
        (Optional / Advanced): </div>
        <select value={keyPath} onChange={(e) => {setKeyPath(e.target.value)}}>
          <option value="m/12381/8444/0/0">m/12381/8444/0/0</option>
          <option value="m/12381/8444/1/0">m/12381/8444/1/0</option>
          <option value="m/12381/8444/2/0">m/12381/8444/2/0</option>
        </select>*/}

                    <form method='POST' onSubmit={generateToken}>
                        <div><label htmlFor='sigPk'>Copy and paste the public key response from chia keys sign:</label></div>
                        <div><textarea style={{ maxWidth: '100%', width: 300, height: 200 }} id='sigPk' placeholder='(example) b405614640856156f001f3fd6091d591c2f1033d588cf5a9320b401be64b40b3f94f5a2740bdc34d3e8a5276cc80a1ab'></textarea></div>

                        <div><label htmlFor='sig'>Copy and paste the signature response from chia keys sign:</label></div>
                        <div><textarea style={{ maxWidth: '100%', width: 300, height: 200 }} id='sig' placeholder='(example) a88513d77e1b401c84c8bcbc2026d49fc327845ce928e8d8d3bfdc1bea626b5467e7a944b391d9c597553e87dd21d85615b00e1ffcbf14aed03db698ee629633ba5c9bfb387b38e69a1fd756f1bbbd16669e9294bdf028f499c12490b71bcb63'></textarea></div>
                        <br />

                        <div className="view-all">
                            <button className='primary'>Register</button></div>

                    </form>
                </div> : ''}
            {error ? <div style={{ 'color': 'red' }}>{error}</div> : ''}
        </div> :

            loggingIn ?

                <div className='page'>
                    {!step ?
                        <div>
                            {intro}
                            <form method='POST' onSubmit={step1}>
                                <div><input type='text' id='fp' size={20} placeholder='(example) 1746461948'></input></div>
                                <br />
                                <button className='primary'>Next</button>
                            </form>
                            <br />
                        </div> : ''}

                    {step == 2 ?
                        <div>
                            <p>Now, we will issue a <code>chia keys sign</code> command</p>
                            <SignMessage id='register' fp={fp} keyPath={keyPath} code='example'></SignMessage>
                            <p>Paste your <strong>public key</strong> response: <ToolTipIcon explanation="We use key path m/12381/8444/0/0" /></p>
                            <form onSubmit={generateLoginSignMessage}>
                                <div><textarea style={{ maxWidth: '100%', width: 300, height: 200 }} id='sigPk' placeholder='(example) b405614640856156f001f3fd6091d591c2f1033d588cf5a9320b401be64b40b3f94f5a2740bdc34d3e8a5276cc80a1ab'></textarea></div>

                                <br />
                                <br />
                                <div><button className='primary' /*</div></div>onClick={generateLoginSignMessage}*/>Get Login Code</button></div>
                                <br />
                            </form>
                        </div>
                        : ''}
                    {loginCode && step == 3 ?
                        <div>
                            <p>Finally, issue this command in the Chia CLI:</p>
                            <SignMessage id='register' fp={fp} keyPath={keyPath} code={loginCode} />

                            <form method='POST' onSubmit={generateLoginToken}>

                                <div><label htmlFor='sig'>Copy and paste the <strong>signature</strong> response from chia keys sign:</label></div>
                                <div><textarea style={{ maxWidth: '100%', width: 300, height: 200 }} id='sig' placeholder='(example) a88513d77e1b401c84c8bcbc2026d49fc327845ce928e8d8d3bfdc1bea626b5467e7a944b391d9c597553e87dd21d85615b00e1ffcbf14aed03db698ee629633ba5c9bfb387b38e69a1fd756f1bbbd16669e9294bdf028f499c12490b71bcb63'></textarea></div>
                                <br />

                                <div className="view-all">
                                    <button className='primary'>Login</button></div>

                            </form>
                        </div> : ''}


                    {error ? <div style={{ 'color': 'red' }}>{error}</div> : ''}

                </div> :


                <div>
                    {spacePoolLogin ?
                        !step ?
                            <div style={{ padding: '20px' }}>
                                <p>Login to your space pool farm as you normally would with <code>View Pool Login Link</code>.</p>

                                <img src={LoginImg}
                                    style={{ maxWidth: '100%', maxHeight: 200 }}
                                >
                                </img>

                                <br />
                                <br />
                                <p>This will give you a login link to authenticate your pool ownership.</p>

                                <p>Once you're logged in to Space Pool, you can create an Explorer account with a single click at the bottom of the account settings:</p>

                                <img src={SPLoginImg}
                                    style={{ maxWidth: '100%', maxHeight: 700 }}>
                                </img>
                                <br />
                                <br />
                                <div><button className='primary' onClick={() => {
                                    setStep(0)
                                    setSpacePoolLogin(false)
                                }}>Nevermind, log in another way</button></div>
                            </div> : ''
                        :

                        <div>
                            <div style={{ padding: '20px' }}><button className='primary' onClick={() => setSpacePoolLogin(true)}>Login with Space Pool</button></div>
                            <p style={{ padding: '20px' }}>or, use <code>chia keys sign</code>:</p>
                            <span style={{ padding: '20px' }}><button className='primary' onClick={() => setRegistering(true)}>Register</button></span>
                            <span><button className='primary' onClick={() => setLoggingIn(true)}>Login</button></span>
                        </div>}
                </div>


    )
}


export default RegisterPage;