import { useState } from "react";
//inspo https://www.emgoto.com/react-inline-edit/
function Edit(props) {
    const [initialValue, setInitialValue] = useState(props.value)
    const [editingValue, setEditingValue] = useState(props.value);
    const onChange = (event) => setEditingValue(event.target.value);
  
   const onKeyDown = (event) => {
    if (event.key === "Enter") {

      let result = props.save(event.target.value, props.address)
      .then((data) => {
          
        setInitialValue(event.target.value)
        event.target.blur();
    }).catch((e) => {
        setEditingValue(initialValue)
        event.target.blur();
    });
    }
    else if(event.key==="Escape"){
        setEditingValue(initialValue)
        event.target.blur();
    }
  }

    return (
      <input
        type="text"
        aria-label="Field name"
        value={editingValue}
        onChange={(onChange)}
        onKeyDown={onKeyDown}
        placeholder={props.placeholder}
        size={props.size}
      />
    )
  }

export default Edit;