import { OverlayTrigger, Popover } from "react-bootstrap";
import { formatMojo, formatXCH } from "../shared";

function XCHMojoHover(props){
    return (
    <>
    <OverlayTrigger
        placement="right"
        delay={{ show: 250, hide: 400 }}
        overlay={
            <Popover id={`popover-positioned-${"bottom"}`}>
            <Popover.Content>
                <div>
                    {props.from.toLowerCase() === 'xch' ? formatMojo(props.val) : props.from.toLowerCase() === 'mojo' ? formatXCH(props.val) : ''}
                </div>
            </Popover.Content>
            </Popover>
        }>
    <span>{props.from.toLowerCase() === 'xch' ? formatXCH(props.val) : props.from.toLowerCase() === 'mojo' ? formatMojo(props.val): ''}</span>
    </OverlayTrigger>
    </>
    )                      
}
export default XCHMojoHover;