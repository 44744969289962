import { t } from "@lingui/macro";
import { OverlayTrigger, Popover } from "react-bootstrap";

export const AddressWarningIcon = () => {
    return (
        <OverlayTrigger
            trigger="click"
            key="stat-box-mempool-header"
            placement={"bottom"}
            rootClose
            overlay={
                <Popover id={`popover-positioned-${"bottom"}`}>
                    <Popover.Content>
                        <div className="mempool-content">
                            <div>{t`Balances shown are estimated and may not yet be up to date.`}<br></br><br></br>{t`A Chia wallet may have more than one address so this balance`}<b> may not match overall wallet balance </b>{t`if multiple wallet addresses have been used or any transactions have been sent from this wallet.`}</div>
                        </div>
                    </Popover.Content>
                </Popover>
            }
        >
            <div style={{ paddingInlineStart: '8px', width: 32, display: 'inline'}}>
            <span style={{ color: 'orange', cursor:'pointer'}}>&#9888;</span>
            </div>
        </OverlayTrigger>
    );
};