import moment from "moment";
import { formatMojo, formatXCH } from "../shared";
import XCHMojoHover from "./xch-mojo-hover";

// not a standard component because I wanted to return an array of two elements instead of a single element.
//wasnt sure how to do that 
//instead invoked like a function()
function XCHToggleValue(val){
    return  [<XCHMojoHover val={val} from={'xch'}/>, <XCHMojoHover val={val} from={'mojo'}/>]
}
export default XCHToggleValue;