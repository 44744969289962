import { t } from "@lingui/macro";
import { OverlayTrigger, Popover } from "react-bootstrap";

export const ConfirmationWarningIcon = () => {
    return (
        <OverlayTrigger
            trigger="click"
            key="stat-box-mempool-header"
            placement={"bottom"}
            rootClose
            overlay={
                <Popover id={`popover-positioned-${"bottom"}`}>
                    <Popover.Content>
                        <div className="mempool-content">
                            <div>{t`The number of block confirmations is low - the block this coin appears on could still get reverted. Our recommendation is to wait for at least 32 confirmation blocks before considering this coin as confirmed.`}</div>
                        </div>
                    </Popover.Content>
                </Popover>
            }
        >
            <div style={{ paddingInlineStart: '8px', width: 32, display: 'inline' }}>
                <span style={{ color: 'orange', cursor: 'pointer' }}>&#9888;</span>
            </div>
        </OverlayTrigger>
    );
};