import classNames from 'classnames';
import { Link } from 'react-router-dom';
import './not-found.scss'

function NotFound(){
    return(
        <div className="block-page" style={{paddingLeft:10}}>
            <div>Resource not found. Check the value and try again.</div>
            <div className='go-home'>
                <Link to='/'>Go Home</Link>
            </div>
        </div>
    )    
}

export default NotFound;
