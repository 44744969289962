import { t } from "@lingui/macro";
import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from "react";
import { Tab, Tabs } from 'react-bootstrap';
import { Link, useParams } from "react-router-dom";
import { CopyIcon } from "../../components/copy-icon/copy-icon";
import NotFound from "../../components/not-found/not-found";
import { SpaceConstraintCoinListItem, SpaceConstraintTable } from "../../components/space-constraint-list/space-constraint-list";
import { SpacePoolTable } from '../../components/spacepool-table/spacepool-table';
import XCHToggleValue from "../../components/xch-toggle-value";
import { baseUrl, formatMojo, formatPerc, formatXCH, getAddress, getAddressFromPuzzle, syncHeight } from '../../shared';
import { isEmbedMode } from '../../utils/navigator-util';
import { TryCoinAsCATCoin } from '../cats/cat-utils';
import { ICoinData } from '../coin/coin-page';
import "./block-page.scss";

export interface IBlockData {
    blockHeight: number;
    blockHundredth: number;
    confirmed: boolean;
    cost: number;
    dayHour: string;
    farmerPuzzleHash: string;
    fees: number;
    headerHash: string;
    newAddressCount: number;
    poolPuzzleHash: string;
    removalsCount: number;
    rewardCount: number;
    signagePointIndex: number;
    timestamp: number;
    totalAddedMojo: number;
    totalAddressCount: number;
    totalIters: number;
    totalRemovedMojo: number;
    totalRewardMojo: number;
    transactionBlock: boolean;
    transactionCount: number;
    weight: number;

    newCatAddressCount: number;
    removalsCountCats: number;
    totalAddedCats: number;
    totalCatAddressCount: number;
    totalRemovedCats: number;
    transactionCountCats: number;
}

function BlockPage(props) {
    const [block, setBlock] = useState<IBlockData>();

    const [isLoading, setIsLoading] = useState(true);
    const [transactions, setTransactions] = useState([]) as any;
    const [removalTransactions, setRemovalTransactions] = useState([]) as any;
    const [isLoadingTransactions, setIsLoadingTransactions] = useState(true);
    const [hideAdditionsButton, setHideAdditionsButton] = useState(false);
    const [hideRemovalsButton, setHideRemovalsButton] = useState(false);
    const [continuationToken, setContinuationToken] = useState('') as any;
    const [removalContinuationToken, setRemovalContinuationToken] = useState('') as any;
    const [err, setErr] = useState(false) as any;
    const [syncPeak, setSyncPeak] = useState(null) as any;

    const { id }: { id: string } = useParams();

    useEffect(() => window.scrollTo(0, 0), []);
    useEffect(() => { onLoadMore(null) }, [id]);
    useEffect(() => { onLoadMoreAdditions(null) }, [id]);
    useEffect(() => { onLoadMoreRemovals(null) }, [id]);
    function onLoadMore(event) {
        if (event) {
            event.preventDefault();
            setIsLoadingTransactions(true);
        } else {
            setIsLoading(true);
        }

        syncHeight().then((data) => {
            setSyncPeak(data.unconfirmedPeakHeight)
        });

        const url = baseUrl + 'api/blocks/height/' + id
        fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw Error("error with response:" + response);
            })
            .then((data) => {
                setIsLoading(false);
                data.farmerAddress = getAddressFromPuzzle(data.farmerPuzzleHash)
                data.poolAddress = getAddressFromPuzzle(data.poolPuzzleHash)

                setBlock(data);
            }
            ).catch((err) => {
                setIsLoading(false)
                setErr(true)
            });
    }

    function onLoadMoreAdditions(event) {
        if (event) {
            event.preventDefault();
            setIsLoadingTransactions(true);
        } else {
            setIsLoading(true);
        }

        fetch(baseUrl + 'api/blocks/' + id + '/coins' + (continuationToken !== '' ? `?continuationToken=${continuationToken}` : ''))
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let newTransactions = [] as any;
                for (const key in data.results) {
                    const transaction = {
                        ...data.results[key],
                    };
                    transaction.toAddress = getAddress(transaction.toAddress)
                    //transaction.fromAddress = getAddress(transaction.fromAddress)
                    newTransactions.push(transaction);
                }
                if (data.continuationToken) {
                    setContinuationToken(data.continuationToken);
                } else {
                    setHideAdditionsButton(true);
                }

                setIsLoadingTransactions(false);

                setTransactions((transactions) => [...transactions, ...newTransactions]);
            });
    }

    function onLoadMoreRemovals(event) {
        if (event) {
            event.preventDefault();
            setIsLoadingTransactions(true);
        } else {
            setIsLoading(true);
        }

        fetch(baseUrl + 'api/blocks/' + id + '/spends' + (removalContinuationToken !== '' ? `?continuationToken=${removalContinuationToken}` : ''))
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let newRemovalTransactions = [] as any;
                for (const key in data.results) {
                    const transaction = {
                        ...data.results[key],
                    };
                    transaction.toAddress = getAddress(transaction.toAddress)
                    //transaction.fromAddress = getAddress(transaction.fromAddress)
                    newRemovalTransactions.push(transaction);
                }
                if (data.continuationToken) {
                    setRemovalContinuationToken(data.continuationToken);
                } else {
                    setHideRemovalsButton(true);
                }

                setIsLoadingTransactions(false);

                setRemovalTransactions((removalTransactions) => [...removalTransactions, ...newRemovalTransactions]);
            });
    }

    if (isLoading && !isEmbedMode()) {
        return (
            <div className="block-page">
                <p>Loading...</p>
            </div>
        );
    }

    if (err) {
        return <NotFound />
    }

    let transactionJSX;
    if (transactions.length !== 0) {
        transactionJSX = (
            <div>
                <div className="block-spacious">
                    <SpacePoolTable
                        headerLine={true}
                        rowLine={true}
                        headers={[
                            {
                                label: 'Coin Id',
                                flexible: true,
                                extraClassName: 'monospace',
                            },
                            {
                                label: [t`XCH`, 'Mojo'],
                                extraClassName: 'text-align-end',
                            },
                            {
                                label: "To Address",
                                extraClassName: 'address-column text-align-end monospace',
                            },
                        ]}

                        rows={transactions.map((coin, i) => [
                            <CoinWithType coin={coin} />,
                            XCHToggleValue(coin.val),
                            <Link to={'/addresses/' + coin.toAddress}>{coin.toAddress}</Link>,
                        ])}
                    />
                </div>
                <SpaceConstraintTable>
                    {transactions.map((coin, i) => <SpaceConstraintCoinListItem key={i} coin={coin} hideBlock={true} hideTimestamp={true} />)}
                </SpaceConstraintTable>
            </div>
        );
    }

    let removalTransactionJSX;
    if (removalTransactions.length !== 0) {
        removalTransactionJSX = (
            <div>
                <div className="block-spacious">
                    <SpacePoolTable
                        headerLine={true}
                        rowLine={true}
                        headers={[
                            {
                                label: 'Coin Id',
                                flexible: true,
                                extraClassName: 'monospace',
                            },
                            {
                                label: [t`XCH`, 'Mojo'],
                                extraClassName: 'text-align-end',
                            },
                            {
                                label: "To Address",
                                extraClassName: 'address-column text-align-end monospace',
                            },
                        ]}

                        rows={removalTransactions.map((coin, i) => [
                            <CoinWithType coin={coin} />,
                            XCHToggleValue(coin.val),
                            <Link to={'/addresses/' + coin.toAddress}>{coin.toAddress}</Link>,
                        ])}
                    />
                </div>
                <SpaceConstraintTable>
                    {removalTransactions.map((coin, i) => <SpaceConstraintCoinListItem key={i} coin={coin} hideBlock={true} hideTimestamp={true} />)}
                </SpaceConstraintTable>
            </div>
        );
    }

    const confirmationBlocks = Math.max((syncPeak || 0) - 1, 0) - (block?.blockHeight || 0);
    const numF = new Intl.NumberFormat();

    return (
        block ? <div className="block-page">
            <h4 style={{ marginBottom: 0 }}><b>Block Record</b> #{new Intl.NumberFormat().format(block?.blockHeight || 0)}</h4>
            <div style={{ marginBottom: '24px' }}>{block?.transactionBlock ? 'Transaction Block' : 'Non-Transaction Block'}</div>

            {
                block && [
                    ['Header Hash', block?.headerHash ? <div className='monospace'>{block.headerHash.toLowerCase()}<CopyIcon text={block.headerHash.toLowerCase()} /></div> : 'Unknown'],
                    ['Farmer Address', block.farmerPuzzleHash ? <div className='monospace'><Link to={'/addresses/' + getAddressFromPuzzle(block.farmerPuzzleHash)}>{getAddressFromPuzzle(block.farmerPuzzleHash)}</Link><CopyIcon text={getAddressFromPuzzle(block.farmerPuzzleHash)} /></div> : 'Unknown'],
                    ['Farmer Puzzle Hash', block?.farmerPuzzleHash ? <div className='monospace'>{block.farmerPuzzleHash.toLowerCase()}<CopyIcon text={block.farmerPuzzleHash.toLowerCase()} /></div> : 'Unknown'],
                    ['Pool Address', block.poolPuzzleHash ? <div className='monospace'><Link to={'/addresses/' + getAddressFromPuzzle(block.poolPuzzleHash)}>{getAddressFromPuzzle(block.poolPuzzleHash)}</Link><CopyIcon text={getAddressFromPuzzle(block.poolPuzzleHash)} /></div> : 'Unknown'],
                    ['Pool Puzzle Hash', block?.poolPuzzleHash ? <div className='monospace'>{block.poolPuzzleHash.toLowerCase()}<CopyIcon text={block.poolPuzzleHash.toLowerCase()} /></div> : 'Unknown'],
                    block?.transactionBlock ? ['Timestamp', block?.timestamp ? <div>{moment(block.timestamp * 1000).fromNow()} ({new Intl.DateTimeFormat(undefined, { dateStyle: 'medium', timeStyle: 'medium' }).format(new Date(block.timestamp * 1000))})</div> : 'Unknown'] : [],
                    ['Block Confirmations', numF.format(confirmationBlocks)],
                    block?.transactionBlock ? ['Addition Txs Count', block?.transactionCount || 0] : [],
                    block?.transactionBlock ? ['Removals Txs Count', block?.removalsCount || 0] : [],
                    block?.transactionBlock ? ['Reward Txs Count', (block?.rewardCount / 2) || 0] : [],
                    block?.transactionBlock ? ['Total CAT Txs Count', (block?.transactionCountCats || 0) | (block?.removalsCountCats || 0)] : [],
                    ['Fees', formatMojo(block?.fees || 0)],
                    block?.transactionBlock ? ['Block Utilization', formatPerc((block.cost || 0) / (11 * (10 ** 9)))] : [],
                    block?.transactionBlock ? ['CLVM Cost', numF.format(block?.cost || 0)] : [],
                    block?.transactionBlock && block?.confirmed ? ['Total Addresses', block?.totalAddressCount] : [],
                    block?.transactionBlock && block?.confirmed ? ['New Addresses', block?.newAddressCount] : [],
                    block?.transactionBlock && block?.confirmed ? ['Est XCH Volume', formatXCH((block?.totalRemovedMojo || 0) + (block?.totalRewardMojo || 0))] : [],
                ].filter(x => x?.length).map((row, i) =>
                    <div key={i} style={{ marginBottom: '16px' }}>
                        <div><b>{row[0]}</b></div>
                        <div style={{ marginRight: '12px', overflow: 'hidden', overflowWrap: 'anywhere' }}> {row[1]}</div>
                    </div>
                )
            }

            {block?.transactionBlock && <h4 style={{ marginTop: '48px' }}>Coins and Rewards</h4>}
            {block?.transactionBlock && <Tabs defaultActiveKey="additions">
                <Tab eventKey="additions" title={t`Addition Coins`}>
                    {transactionJSX}
                    <div className="view-all">
                        <a href="/" onClick={onLoadMoreAdditions}>
                            <button
                                disabled={isLoadingTransactions}
                                hidden={hideAdditionsButton}
                                className="primary"
                            >
                                {t`Load More`}
                            </button>
                        </a>
                    </div>
                </Tab>
                <Tab eventKey="removals" title={t`Removal Coins`}>
                    {removalTransactionJSX}
                    <div className="view-all">
                        <a href="/" onClick={onLoadMoreRemovals}>
                            <button
                                disabled={isLoadingTransactions}
                                hidden={hideRemovalsButton}
                                className="primary"
                            >
                                {t`Load More`}
                            </button>
                        </a>
                    </div>
                </Tab>
            </Tabs>}
        </div> : <></>
    );
}

const CoinWithType = (props: { coin: ICoinData }) => {
    const catCoin = TryCoinAsCATCoin(props.coin);
    return (
        <div className='coin-with-type'>
            <div className={classNames('coin-type', catCoin ? 'cat' : 'xch')}>{catCoin ? 'CAT' : 'XCH'}</div>
            <Link to={'/coins/' + props.coin.coinId.toLowerCase()}>{props.coin.coinId.toLowerCase()}</Link>
        </div>
    );
};

export default BlockPage;
