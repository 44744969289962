import moment from "moment";

const dateFormat = new Intl.DateTimeFormat(undefined, { dateStyle: 'short', timeStyle: 'short' });

// not a standard component because I wanted to return an array of two elements instead of a single element.
//wasnt sure how to do that 
//instead invoked like a function()
function DateToggleValue(timestamp) {
    return [<span title={dateFormat.format(new Date(timestamp * 1000))}>{moment(timestamp * 1000).fromNow()}</span>, <span title={moment(timestamp * 1000).fromNow()}>{dateFormat.format(new Date(timestamp * 1000))}</span>]
}
export function DateToggleValueWithClass(timestamp) {
    return [<div className='spacepool-table-column-value'><span title={dateFormat.format(new Date(timestamp * 1000))}>{moment(timestamp * 1000).fromNow()}</span></div>, <div className='spacepool-table-column-value'><span title={moment(timestamp * 1000).fromNow()}>{dateFormat.format(new Date(timestamp * 1000))}</span></div>]
}

export default DateToggleValue;