import { baseUrl } from "../shared";

export const exchangeToken = (spLoginToken: string, spLauncherId: string) => {

    fetch(baseUrl + 'api/accounts/Login/exchange', {method:'POST', 
    headers: {
        'Content-Type': 'application/json'
    },
    body: JSON.stringify({
        loginToken: spLoginToken,
        launcherId: spLauncherId.indexOf('0x') === 0 ? spLauncherId : `0x${spLauncherId}`
    })})
    .then(response => {
        if(response.ok){
            return response.json()
        } 
        throw "Unable to exchange token";  
    })
    .then(data => {
        localStorage.setItem('loginToken', data.loginToken);
        localStorage.setItem('accountId', data.accountId);
        localStorage.setItem('expirationDate', data.expirationDate);
        window.location.href = '/account';
    }).catch((err) => {
        console.log(err);
    })

}