import { bech32m } from 'bech32';
import { Buffer } from "buffer";

export const mainnetFrontendURL = 'https://xch.space';
export const testnetFrontendURL = 'https://testnet10.xch.space';

const mainnetFrontendURLs = [
    'http://localhost:3000',
    'https://xch.space',
    'https://mobile.xch.space',
];

export const testnet = !mainnetFrontendURLs.find(x => window.location.href.startsWith(x));

const mainnetApiUrl = 'https://beta-api.xch.space/';
const testnetApiUrl = 'https://testnet10-api.xch.space/';
export const baseUrl = testnet ? testnetApiUrl : mainnetApiUrl;

//export const baseUrl = 'http://localhost:5000/';


export enum AddressPrefix {
    Testnet = 'txch',
    Mainnet = 'xch',
    NFT = 'nft',
    DID = 'did:chia:'
}

export const syncHeight = function () {
    return fetch(baseUrl + 'api/height/')
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            return data
        });
}

export const formatXCH = function (x) {
    return new Intl.NumberFormat(undefined, { maximumFractionDigits: 12 }).format(+(x / 1000000000000).toFixed(12).replace(/\.?0+$/, "")) + (testnet ? ' TXCH' : ' XCH')
}

export const formatMojo = function (x) {
    return new Intl.NumberFormat().format(x) + ' Mojo';
}

export const formatPerc = function (x) {
    return new Intl.NumberFormat().format(x * 100) + ' %';
}

export const addressToPuzzle = (address: string, prepend0x = false) => {
    const { words } = bech32m.decode(address);
    const buffer = bech32m.fromWords(words);
    return prepend0x ? `0x${Buffer.from(buffer).toString('hex')}` : Buffer.from(buffer).toString('hex');

};

export const puzzleToAddress = (puzzle: string, addressPrefix: AddressPrefix) => {
    puzzle = puzzle.startsWith('0x') ? puzzle.substr(2) : puzzle;


    const fromHexString = (hexString: string) =>
        new Uint8Array(
            (hexString.match(/.{1,2}/g) || []).map(byte => parseInt(byte, 16))
        );
    const buffer = fromHexString(puzzle);
    const words = bech32m.toWords(buffer);
    return bech32m.encode(addressPrefix, words);
};

export const getAddress = (address: string) => {
    if (!testnet) {
        return address;
    } else {
        return puzzleToAddress(addressToPuzzle(address), AddressPrefix.Testnet)
    }
}

export const getAddressFromPuzzle = (address: string) => {
    if (!testnet) {
        return puzzleToAddress(address, AddressPrefix.Mainnet);
    } else {
        return puzzleToAddress(address, AddressPrefix.Testnet)
    }
}

export const getXCHAddressFromTXCH = (address: string) => {
    return puzzleToAddress(addressToPuzzle(address), AddressPrefix.Mainnet)
}

export const knownAddresses = new Map([
    ['xch19ukfhgdjx9w5zw5jkhcrf7sr9qkvhgtk0lv6u7c5m9ptj60dt4tscqkmna', 'Space Pool'],
    ['xch1l2xwmhe6vqputljnw8dv8ajfustycfk87yy7ttskuvlfqt42chxstd5tgz', 'Core-Pool'],
    ['xch1f0ryxk6qn096hefcwrdwpuph2hm24w69jnzezhkfswk0z2jar7aq5zzpfj', 'Hpool OG'],
    ['xch1uwu6m38tp8gcmqaa2eyz4mjkdqs0tt7uuk2m8mgft6ekch80jvqswdhmlh', 'Hpool OP'],
    ['xch1f0ctuf9v9yj2rjvqmrvrh4d23y3a8mav8gfc6xrex2nhsslx7yss3u3t3p', 'Poolharvest.io'],
    ['xch1pkpv9ceqx7mhm2wglh2ar486fwx05zqzd5kqfq6fq6m5rlwtdl3qyz7076', 'XCHpool'],
    ['xch1d00purr0n5ae8hz706rcwge90m09w00wa4v78d9fpawgdhs6p0fsjt6rd8', 'Flexpool'],
    ['xch18krkt5a9jlkpmxtx8akfs9kezkuldpsn4j2qpxyycjka4m7vu6hstf6hku', 'Prefarm 1'],
    ['xch16g76z3545xy2u4cgm52jyc7ymwyravn7m6unv9udfkvghreuuh7qa9cvfl', 'Prefarm 2'],
    ['xch1duvy5ur5eyj7lp5geetfg84cj2d7xgpxt7pya3lr2y6ke3696w9qvda66e', 'Prefarm 3'],
    ['xch1rdatypul5c642jkeh4yp933zu3hw8vv8tfup8ta6zfampnyhjnusxdgns6', 'Prefarm 4'],
    ['xch183rqja3f2ak4f72w9enc88v0788vjp5ns3c2n2sctxpcnewzh2yqtcu2rs', 'Gigahorse Fee Address'],
    ['xch1w6cjx6tygnlzted2ykacw2hqwxffkdt595r05rdxqpudq90gjhrs76ql54', 'Gigahorse Fee Address'],
    ['xch178wppppm8pqp9tkalcurclln2q54snvs3estx5fmfhp936pzdajszh83t9', 'Gigahorse Fast Farmer Fee Address'],
    ['', ''],
    ['', ''],
    ['', ''],
    ['', ''],
    ['', ''],
    ['', ''],
    ['', ''],
    ['', ''],
    ['', ''],
    ['', ''],
    ['', ''],
    ['', ''],
])