import { combineEpics, Epic } from "redux-observable";
import { EMPTY } from "rxjs";
import { filter, mergeMap } from "rxjs/operators";
import { isActionOf } from "typesafe-actions";
import { RootState } from "./index.reducers";
import { chiaQuoteEpic } from "./chia-quote.epic";
import { poolStatsEpic } from './pool-stats.epic';
import statisticsEpic from "./pages/statistics/statistics.epic";
import { actions, ActionsType } from "./store";
import { setCookie } from "./utils/cookie-manager";

export const darkModeCookieEpic: Epic<ActionsType, ActionsType, RootState> = action$ => {
    return action$.pipe(
        filter(isActionOf(actions.setDarkModeAction)),
        mergeMap(action => {
            setCookie('darkMode', action.payload.darkMode ? '1' : '0');
            return EMPTY;
        }),
    );
}

const epics = combineEpics(...[
    chiaQuoteEpic,
    poolStatsEpic,
    statisticsEpic,
    darkModeCookieEpic,
]);

export default epics;
