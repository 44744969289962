import { Link } from 'react-router-dom';
import './cat-profile.scss';

export const CATProfile = (props: { cat: { tail: string, logoUrl?: string, name?: string } }) => {
    return (
        <Link to={`/cat/${props.cat.tail}`}>
            <div className='cat-profile'>
                {props.cat.logoUrl ? <img className='logo' src={props.cat.logoUrl} /> : <div className='placeholder'>?</div>}
                {props.cat.name || `Nameless CAT (${props.cat.tail.substring(props.cat.tail.length - 4)})`}
            </div>
        </Link>
    );
};