import { i18n } from '@lingui/core';
import moment from 'moment';
import {
  bg,
  cs,
  de,
  el,
  en,
  es,
  fr,
  hu,
  it,
  ja,
  nl,
  pl,
  ps,
  pt,
  ro,
  ru,
  sq,
  tr,
  th,
  zh,
} from 'make-plural/plurals';
import { isEmbedMode, isEmbedModeDogfood } from '../utils/navigator-util';

const catalogBgBG = require('../locales/bg-BG/messages');
const catalogCsCZ = require('../locales/cs-CZ/messages');
const catalogDe = require('../locales/de-DE/messages');
const catalogElGR = require('../locales/el-GR/messages');
const catalogEn = require('../locales/en-US/messages');
const catalogEs = require('../locales/es-ES/messages');;
const catalogFil = require('../locales/fil-PH/messages');
const catalogFr = require('../locales/fr-FR/messages');
const catalogHu = require('../locales/hu-HU/messages');
const catalogIt = require('../locales/it-IT/messages');
const catalogJa = require('../locales/ja-JP/messages');
const catalogNl = require('../locales/nl-NL/messages');
const catalogPl = require('../locales/pl-PL/messages');
const catalogPt = require('../locales/pt-PT/messages');
const catalogPtBR = require('../locales/pt-BR/messages');
const catalogRo = require('../locales/ro-RO/messages');
const catalogRu = require('../locales/ru-RU/messages');
const catalogSq = require('../locales/sq-AL/messages');
const catalogThTH = require('../locales/th-TH/messages');
const catalogTrTR = require('../locales/tr-TR/messages');
const catalogZhCN = require('../locales/zh-CN/messages');
const catalogZhTW = require('../locales/zh-TW/messages');

export const defaultLocale = 'en-US';

// en-hr - croation 

export const locales = [
  {
    locale: 'bg-BG',
    label: 'български', // Bulgarian
  },
  {
     locale: 'cs-CZ',  // Czech
     label: 'Čeština',
  },
  {
    locale: 'de-DE', // German
    label: 'Deutsch',
  },
  {
    locale: 'el-GR',   // Greek
    label: 'Ελληνικά',
  },
  {
    locale: 'en-US',
    label: 'English',
  },
  {
    locale: 'es-ES',
    label: 'Español',
  },
  {
    locale: 'fil-PH',
    label: 'Filipino'
  },
  {
    locale: 'fr-FR',
    label: 'Français',
  },
  {
    locale: 'it-IT',
    label: 'Italiano',
  },
  {
    locale: 'ja-JP',
    label: '日本語 (日本)',
  },
  {
    locale: 'hu-HU',  // Hungarian
    label: "Magyar"
  },
  {
    locale: 'nl-NL',    // Dutch
    label: 'Nederlands',
  },
  {
    locale: 'pl-PL',  // Polish
    label: 'Polski',
  },
  {
    locale: 'pt-PT',
    label: 'Português',
  },
  {
    locale: 'pt-BR',
    label: 'Português (Brasil)',
  },
  {
    locale: 'ro-RO',  // Romanian
    label: 'Română',
  },
  {
    locale: 'ru-RU',    // Russian
    label: 'Русский',
  },
  {
    locale: 'sq-AL',
    label: 'Shqiptare', // Albanian
  },
  {
    locale: 'th-TH',
    label: 'ไทย' // Thai
  },
  {
    locale: 'tr-TR', // Turkish
    label: 'Türkçe',
  },
  {
    locale: 'zh-CN', // Simplified chinese
    label: '简体中文',
  },
  {
     locale: 'zh-TW',
     label: '繁體中文', // Traditional chinese
  }
];

i18n.loadLocaleData('bg-BG', { plurals: bg });
i18n.loadLocaleData('cs-CZ', { plurals: cs });
i18n.loadLocaleData('de-DE', { plurals: de });
i18n.loadLocaleData('el-GR', { plurals: el });
i18n.loadLocaleData('en-US', { plurals: en });
i18n.loadLocaleData('es-ES', { plurals: es });
i18n.loadLocaleData('fil-PH', { plurals: en });
i18n.loadLocaleData('fr-FR', { plurals: fr });
i18n.loadLocaleData('hu-HU', { plurals: hu });
i18n.loadLocaleData('it-IT', { plurals: it });
i18n.loadLocaleData('ja-JP', { plurals: ja });
i18n.loadLocaleData('nl-NL', { plurals: nl });
i18n.loadLocaleData('pl-PL', { plurals: pl });
i18n.loadLocaleData('pt-PT', { plurals: pt });
i18n.loadLocaleData('pt-BR', { plurals: pt });
i18n.loadLocaleData('ro-RO', { plurals: ro });
i18n.loadLocaleData('ru-RU', { plurals: ru });
i18n.loadLocaleData('sq-AL', { plurals: sq });
i18n.loadLocaleData('th-TH', { plurals: th });
i18n.loadLocaleData('tr-TR', { plurals: tr });
i18n.loadLocaleData('zh-CN', { plurals: zh });
i18n.loadLocaleData('zh-TW', { plurals: zh });
i18n.load('bg-BG', catalogBgBG.messages);
i18n.load('cs-CZ', catalogCsCZ.messages);
i18n.load('de-DE', catalogDe.messages);
i18n.load('el-GR', catalogElGR.messages);
i18n.load('en-US', catalogEn.messages);
i18n.load('es-ES', catalogEs.messages);
i18n.load('fil-PH', catalogFil.messages);
i18n.load('fr-FR', catalogFr.messages);
i18n.load('hu-HU', catalogHu.messages);
i18n.load('ja-JP', catalogJa.messages);
i18n.load('it-IT', catalogIt.messages);
i18n.load('nl-NL', catalogNl.messages);
i18n.load('pl-PL', catalogPl.messages);
i18n.load('pt-BR', catalogPtBR.messages);
i18n.load('pt-PT', catalogPt.messages);
i18n.load('ro-RO', catalogRo.messages);
i18n.load('ru-RU', catalogRu.messages);
i18n.load('sq-AL', catalogSq.messages);
i18n.load('th-TH', catalogThTH.messages);
i18n.load('tr-TR', catalogTrTR.messages);
i18n.load('zh-CN', catalogZhCN.messages);
i18n.load('zh-TW', catalogZhTW.messages);

export function activateLocale(locale: string) {
  i18n.activate(locale);
  moment.locale([locale, 'en']);
}

export { i18n };

export const getBrowserLanguage = () => {
  try {
    if (isEmbedMode()) {
      return;
    }
    var browserLanguage = navigator.language;
    for (var loc of locales) {
      var localeLangOnly = loc.locale.split('-')[0];
      var browserLocaleLangOnly = browserLanguage.split('-')[0];
      if (localeLangOnly === browserLocaleLangOnly || loc.locale.toLowerCase() === browserLanguage.toLowerCase()) {
        return loc.locale;
      }
    }
  } catch(e) {
    console.log(e);
  }
};

let locale = window.localStorage['locale'] || getBrowserLanguage() || defaultLocale;
if (locale && locale.length === 2) {
  locale = defaultLocale;
}

activateLocale(locale);