import { t, Trans } from '@lingui/macro';
import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { CopyIcon } from '../../components/copy-icon/copy-icon';
import NotFound from '../../components/not-found/not-found';
import { SpaceConstraintCoinListItem, SpaceConstraintTable } from '../../components/space-constraint-list/space-constraint-list';
import { SpacePoolTable } from '../../components/spacepool-table/spacepool-table';
import XCHMojoHover from '../../components/xch-mojo-hover';
import XCHToggleValue from '../../components/xch-toggle-value';
import { addressToPuzzle, baseUrl, getAddress, syncHeight } from '../../shared';
import { isEmbedMode } from '../../utils/navigator-util';
import { CATProfile } from '../cats/cat-profile';
import { GlobalSettings, ICATData, NUM_FORMAT, TryCoinAsCATCoin } from '../cats/cat-utils';
import { ConfirmationWarningIcon } from './confirmation-warning-icon';
import './transactions-page.scss';

export interface ICoinData {
    blockHeight: number;
    coinId: string;
    coinType: 'cat_v2' | 'standard' | 'unknown';
    coinbase: boolean;
    confirmed: boolean;
    dayHour: string;
    parentCoin: string;
    puzzleHash: string;
    spent: boolean;
    spentBlock: number;
    timestamp: number;
    fromAddress?: string;
    toAddress: string;
    val: number;
}

export interface ICATCoinData extends ICoinData {
    coinType: 'cat_v2';
    catDetails: ICATData;
    fromAddressInner: string;
    toAddressInner: string;
    tail: string;
}

function CoinPage() {
    const [transaction, setTransaction] = useState<ICoinData>();
    const [isLoading, setIsLoading] = useState(true);
    const [isChildrenLoading, setIsChildrenLoading] = useState(true);
    const [syncPeak, setSyncPeak] = useState(null) as any;
    const { id }: { id: string } = useParams();
    const [err, setErr] = useState(false) as any;
    const [continuationToken, setContinuationToken] = useState('') as any;
    const [hideButton, setHideButton] = useState(false) as any;
    const [loadedChildren, setLoadedChildren] = useState<ICoinData[]>([]);
    const [coinFilter, setCoinFilter] = useState<typeof GlobalSettings.coinFilter>(GlobalSettings.coinFilter);

    useEffect(() => window.scrollTo(0, 0), []);

    function handleCoinFilterChanged(e: React.ChangeEvent<HTMLInputElement>) {
        setCoinFilter(e.currentTarget.value as any || 'xch');
        GlobalSettings.coinFilter = (e.currentTarget.value as any) || 'xch';
    }

    function onLoadMore(event) {
        if (event) {
            event.preventDefault();
        }

        syncHeight().then((data) => {
            setSyncPeak(data.unconfirmedPeakHeight)
        });
        fetch(baseUrl + 'api/coins/' + id)
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setIsLoading(false);
                // console.log('data', data)
                data.toAddress = getAddress(data.toAddress)
                if (data.fromAddress) {
                    data.fromAddress = getAddress(data.fromAddress)
                }
                data.fromAddressInner && (data.fromAddressInner = getAddress(data.fromAddressInner));
                data.toAddressInner && (data.toAddressInner = getAddress(data.toAddressInner));

                setTransaction(data);
            }).catch((err) => {
                console.log('err', err)
                setIsLoading(false)
                setErr(true)
            });

        fetch(baseUrl + 'api/coins/' + id + `/children` + (continuationToken !== '' ? `?continuationToken=${continuationToken}` : ''))
            .then((response) => {
                if (response.ok)
                    return response.json();
            })
            .then((data) => {
                let children = [] as any;
                //setIsChildrenLoading(false);
                //setChildren(data);
                for (const key in data.results) {
                    const child = {
                        ...data.results[key],
                    };

                    child.toAddress = getAddress(child.toAddress)
                    child.fromAddress = getAddress(child.fromAddress)
                    child.fromAddressInner && (child.fromAddressInner = getAddress(child.fromAddressInner));
                    child.toAddressInner && (child.toAddressInner = getAddress(child.toAddressInner));

                    children.push(child);
                }
                if (data.continuationToken) {
                    setContinuationToken(data.continuationToken);
                } else {
                    setHideButton(true);
                }
                setLoadedChildren((loadedChildren) => [...loadedChildren, ...children]);
                setIsLoading(false)
                setIsChildrenLoading(false)
            }).catch((err) => {
                console.log('error', err)
            });
    }

    useEffect(() => onLoadMore(null), [id]);
    if (err) {
        return <NotFound />
    }

    if ((isLoading || isChildrenLoading || !syncPeak) && !isEmbedMode) {
        return (
            <div className="transaction-page">
                <p>{t`Loading...`}</p>
            </div>
        );
    }

    const catCoin = transaction?.coinType === 'cat_v2' ? (transaction as ICATCoinData) : undefined;
    let transactionJSX;
    if (loadedChildren.length !== 0) {
        transactionJSX = (
            <div>
                <h4>{t`Children Coins`}</h4>
                <div className="transaction-spacious">
                    <SpacePoolTable
                        headerLine={true}
                        rowLine={true}
                        headers={[
                            {
                                label: 'Coin Id',
                                flexible: true,
                                extraClassName: 'monospace',
                            },
                            {
                                label: 'Block',
                            },
                            {
                                label: coinFilter === 'cats' ? 'Amount' : [t`XCH`, t`Mojo`],
                                extraClassName: 'value-column text-align-end',
                            },
                            {
                                label: [t`Address`, t`Puzzle`],
                                extraClassName: 'monospace',
                            },
                        ]}
                        rows={loadedChildren.map((coin, i) => {
                            const childCoin = TryCoinAsCATCoin(coin);
                            const toAddress = coinFilter === 'cats' ? childCoin?.toAddressInner : coin.toAddress;
                            return [
                                <Link to={'/coins/' + coin.coinId.toLowerCase()}>
                                    {coin.coinId.toLowerCase()}
                                </Link>,

                                <Link to={'/blocks/' + coin.blockHeight}>
                                    {coin.blockHeight.toLocaleString()}
                                </Link>,

                                coinFilter === 'cats' ? `${NUM_FORMAT.format(coin.val / 1000)} ${catCoin?.catDetails?.code || 'CAT'}` : XCHToggleValue(coin.val),

                                coin.coinbase === true ? (
                                    'Farming Reward'
                                ) : (
                                    [
                                        <Link to={'/addresses/' + toAddress}>
                                            {toAddress}
                                        </Link>,
                                        <Link to={'/addresses/' + toAddress}>
                                            {addressToPuzzle(toAddress || '', true)}
                                        </Link>,
                                    ]
                                ),
                            ];
                        })}
                    />
                </div>
                <SpaceConstraintTable>
                    {loadedChildren.map((coin, i) => <SpaceConstraintCoinListItem key={i} coin={coin} />)}
                </SpaceConstraintTable>
            </div>
        );
    }

    const confirmationBlocks = Math.max((syncPeak || 0) - 1, 0) - (transaction?.blockHeight || 0);
    const coinbaseBlock = (transaction?.coinbase && transaction.parentCoin) ? parseInt(transaction.parentCoin.substr(-6), 16) : 0;
    const numF = new Intl.NumberFormat();
    let fromAddress = transaction?.fromAddress;
    let toAddress = transaction?.toAddress;
    if (catCoin) {
        fromAddress = coinFilter === 'cats' ? catCoin?.fromAddressInner : transaction?.fromAddress;
        toAddress = coinFilter === 'cats' ? catCoin?.toAddressInner : transaction?.toAddress;
    } else {
        GlobalSettings.coinFilter = 'xch';
    }

    return (
        transaction ? <div className="transaction-page">
            <h4 style={{ overflowWrap: 'anywhere' }}><b>Coin Record</b> <span className='monospace'>{(transaction?.coinId || '').toLowerCase() || 'Unknown'}</span><CopyIcon text={(transaction?.coinId || '').toLowerCase()} /></h4>
            {[
                [catCoin ? t`Scope` : '', <div>
                    <label className='coin-filter-option'>
                        {catCoin && <input name='coin_filter' type='radio' value='xch' checked={coinFilter == 'xch'} onChange={handleCoinFilterChanged} />}
                        <div className={classNames('address-badge', 'xch', coinFilter == 'xch' ? 'selected' : '')}>XCH</div>
                    </label>
                    {
                        catCoin && <label className='coin-filter-option'>
                            <input name='coin_filter' type='radio' value='cats' checked={coinFilter == 'cats'} onChange={handleCoinFilterChanged} />
                            <div className={classNames('address-badge', 'cat', coinFilter == 'cats' ? 'selected' : '')} title={t`This coin is related to a CAT transaction.`}>CAT</div>
                        </label>
                    }
                </div>],
                (coinFilter === 'cats' && catCoin) ? ['CAT', <CATProfile cat={catCoin.catDetails} />] : undefined,
                [coinFilter === 'cats' ? 'From Address (Inner)' : 'From Address', fromAddress ? <div className='monospace'><Link to={'/addresses/' + fromAddress}>{fromAddress}</Link><CopyIcon text={fromAddress} /></div> : (transaction?.coinbase ? 'Farming Reward' : 'Unknown')],
                [coinFilter === 'cats' ? 'To Address (Inner)' : 'To Address', toAddress ? <div className='monospace'><Link to={'/addresses/' + toAddress}>{toAddress}</Link><CopyIcon text={toAddress} /></div> : 'Unknown'],
                ['Parent Coin', transaction?.parentCoin ? (transaction.coinbase ? <div className='monospace'>{transaction.parentCoin.toLowerCase()}<CopyIcon text={transaction.parentCoin.toLowerCase()} /> (Coinbase of block <Link to={`/blocks/${coinbaseBlock}`}>{numF.format(coinbaseBlock)}</Link>)</div> : <div className='monospace'><Link to={'/coins/' + transaction.parentCoin.toLowerCase()}>{transaction.parentCoin.toLowerCase()}</Link><CopyIcon text={transaction.parentCoin.toLowerCase()} /></div>) : 'Unknown'],
                [(coinFilter === 'cats' && catCoin) ? 'Amount' : 'Value', (coinFilter === 'cats' && catCoin) ? `${numF.format(catCoin.val / 1000)} ${catCoin.catDetails.code ? catCoin.catDetails.code : 'CAT'}` : <XCHMojoHover val={transaction?.val || 0} from={'xch'} />],
                ['Confirmation Block', <div><Link to={'/blocks/' + (transaction?.blockHeight || 0)}>{numF.format(transaction?.blockHeight || 0)}</Link> ({numF.format(confirmationBlocks)} block confirmations, {moment().subtract({ seconds: 18.75 * confirmationBlocks }).fromNow()}){confirmationBlocks < 32 ? <ConfirmationWarningIcon /> : null}</div>],
                ['Timestamp', transaction?.timestamp ? <div>{new Intl.DateTimeFormat(undefined, { dateStyle: 'long', timeStyle: 'long' }).format(new Date(transaction.timestamp * 1000))} ({moment(transaction.timestamp * 1000).fromNow()})</div> : 'Unknown'],
                ['Status', transaction?.spent ? <div>Spent on <Link to={'/blocks/' + (transaction.spentBlock || 0)}>block {numF.format(transaction.spentBlock || 0)}</Link></div> : 'Unspent'],
            ].filter(x => x?.length).map((row, i) =>
                <div key={i} style={{ marginBottom: '16px' }}>
                    <div><b>{row![0]}</b></div>
                    <div style={{ marginRight: '12px', overflow: 'hidden', overflowWrap: 'anywhere' }}> {row![1]}</div>
                </div>
            )}
            {transactionJSX}

            <div className="view-all">
                <a href="/" onClick={onLoadMore}>
                    <button
                        disabled={isLoading}
                        hidden={hideButton}
                        className="primary"
                    >
                        <Trans>Load More</Trans>
                    </button>
                </a>
            </div>
        </div> : <></>
    );
}

export default CoinPage;
