import { t } from "@lingui/macro";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import { ICATBalance } from '../../pages/address/address-page';
import { FORMAT_TAIL, ICATData, NUM_FORMAT, TryCoinAsCATCoin } from "../../pages/cats/cat-utils";
import { ICoinData } from '../../pages/coin/coin-page';
import { formatXCH } from "../../shared";
import { mobilePostMessage } from "../../utils/navigator-util";

const numF = new Intl.NumberFormat();
const dateF = new Intl.DateTimeFormat(undefined, { dateStyle: 'short', timeStyle: 'short' });

const _MobileCompatLink = (props: { to: string, dataId: string, dataPageName: string, children: any }) => {
    const history = useHistory();

    const onNavigateTo = (ev: any) => {
        if (navigator.userAgent.indexOf('SpacePoolApp') >= 0) {
            mobilePostMessage(JSON.stringify({ navigateTo: 'Modal', id: ev.target.dataset.id || '', pagename: ev.target.dataset.pagename }), '*')
        } else {
            history.push(`/${ev.target.dataset.pagename}/${ev.target.dataset.id || ''}`);
        }
        ev.preventDefault();
    };

    return navigator.userAgent.indexOf('SpacePoolApp') >= 0
        ? <a href={`/`} onClick={onNavigateTo} data-id={props.dataId} data-pagename={props.dataPageName}>
            {props.children}
        </a>
        : <Link to={props.to}>{props.children}</Link>;
};

export const SpaceConstraintTable = (props: { children: React.FunctionComponentElement<ReturnType<typeof SpaceConstraintItem>>[] }) =>
    <div className='space-constraint-table'>
        {props.children}
    </div>;

export const SpaceConstraintItem = (props: { children: (React.FunctionComponentElement<ReturnType<typeof SpaceConstraintItemDetail>> | boolean | undefined | null | string | number)[] }) =>
    <div style={{ marginTop: '16px', paddingBottom: '16px', borderBottom: '1px solid rgba(128, 128, 128, 0.5)' }}>
        {props.children.filter(x => !!x)}
    </div>;

export const SpaceConstraintItemDetail = (props: { header: string, value?: string | number, link?: string, compatLink?: { id: string, pageName: string } }) =>
    <div className='scl-item' style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className='scl-item-header' style={{ flexShrink: 0 }}><b>{props.header}</b></div>
        {
            <div className='scl-item-value' style={{ overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '80%' }}>{
                props.link
                    ? ((props.compatLink)
                        ? <_MobileCompatLink to={props.link} dataId={props.compatLink.id} dataPageName={props.compatLink.pageName}>{props.value}</_MobileCompatLink>
                        : <Link to={props.link}>{props.value}</Link>)
                    : props.value
            }</div>
        }
    </div>;

export const SpaceConstraintBlockListItem = (props: { block: any }) =>
    <SpaceConstraintItem>
        <SpaceConstraintItemDetail header={t`Block`} value={numF.format(props.block.blockHeight || 0)} link={'/blocks/' + props.block.blockHeight} compatLink={{ id: props.block.blockHeight, pageName: 'blocks' }} />
        <SpaceConstraintItemDetail header={t`Farmer`} value={props.block.farmerAddress} link={'/addresses/' + props.block.farmerAddress} compatLink={{ id: props.block.farmerAddress, pageName: 'addresses' }} />
        {props.block.transactionBlock && <SpaceConstraintItemDetail header={'Txs'} value={t`${numF.format(props.block.transactionCount)} Txs, ${props.block.rewardCount} Rewards`} />}
        {props.block.timestamp && <SpaceConstraintItemDetail header={t`Timestamp`} value={dateF.format(new Date(props.block.timestamp * 1000))} />}
        {props.block.timestamp && <SpaceConstraintItemDetail header={''} value={moment(props.block.timestamp * 1000).fromNow()} />}
        {!props.block.transactionBlock && <SpaceConstraintItemDetail header={''} value={t`Non-Transaction Block`} />}
    </SpaceConstraintItem>;

export const SpaceConstraintCoinListItem = (props: { coin: ICoinData, hideFrom?: boolean, hideTo?: boolean, hideBlock?: boolean, hideTimestamp?: boolean }) => {
    const catCoin = TryCoinAsCATCoin(props.coin);
    const fromAddress = catCoin ? catCoin.fromAddressInner : props.coin.fromAddress;
    const toAddress = catCoin ? catCoin.toAddressInner : props.coin.toAddress;
    return (
        <SpaceConstraintItem>
            <SpaceConstraintItemDetail header={t`Coin Id`} value={props.coin.coinId.toLowerCase()} link={'/coins/' + props.coin.coinId.toLowerCase()} />
            <SpaceConstraintItemDetail header='Coin Type' value={catCoin ? `CAT (${catCoin.tail.substring(catCoin.tail.length - 4)})` : 'XCH'} link={catCoin ? `/cat/${catCoin.tail}` : `/coins/${props.coin.coinId.toLocaleLowerCase()}`} />
            <SpaceConstraintItemDetail header={t`Value`} value={formatXCH(props.coin.val)} />
            {!props.hideFrom && <SpaceConstraintItemDetail header={t`From`} value={props.coin.coinbase ? t`Farming Reward` : fromAddress} link={!props.coin.coinbase ? '/addresses/' + fromAddress : undefined} />}
            {!props.hideTo && props.coin.toAddress && <SpaceConstraintItemDetail header={t`To`} value={toAddress} link={'/addresses/' + toAddress} />}
            {!props.hideBlock && <SpaceConstraintItemDetail header={t`Block`} value={numF.format(props.coin.blockHeight || 0)} link={'/blocks/' + props.coin.blockHeight} />}
            {!props.hideTimestamp && props.coin.timestamp && <SpaceConstraintItemDetail header={t`Timestamp`} value={dateF.format(new Date(props.coin.timestamp * 1000))} />}
            {!props.hideTimestamp && props.coin.timestamp && <SpaceConstraintItemDetail header={''} value={moment(props.coin.timestamp * 1000).fromNow()} />}
        </SpaceConstraintItem>
    );
};

export const SpaceConstraintCATListItem = (props: { cat: ICATBalance }) =>
    <SpaceConstraintItem>
        <SpaceConstraintItemDetail header={`CAT`} value={`Nameless CAT (${props.cat.tail.substring(props.cat.tail.length - 4)})`} link={`/cat/${props.cat.tail}`} />
        <SpaceConstraintItemDetail header={`Tail`} value={FORMAT_TAIL(props.cat.tail)} link={`/cat/${props.cat.tail}`} />
        <SpaceConstraintItemDetail header={`Balance`} value={props.cat.balance} />
    </SpaceConstraintItem>

export const SpaceConstraintCATTailListItem = (props: { cat: ICATData }) =>
    <SpaceConstraintItem>
        {props.cat.logoUrl ? <div style={{ display: 'flex', justifyContent: 'end' }}> <img style={{ width: '100%', maxWidth: '480px', paddingTop: '64px' }} className='space-constraint-cat-tail-item' src={props.cat.logoUrl} /></div> : undefined}
        {props.cat.name ? <SpaceConstraintItemDetail header={`Name`} value={props.cat.name || `Nameless CAT`} link={`/cat/${props.cat.tail}`} compatLink={{ id: props.cat.tail, pageName: 'cat' }} /> : undefined}
        <SpaceConstraintItemDetail header={`Tail`} value={FORMAT_TAIL(props.cat.tail)} link={`/cat/${props.cat.tail}`} compatLink={{ id: props.cat.tail, pageName: 'cat' }} />
        <SpaceConstraintItemDetail header={`Volume`} value={`${NUM_FORMAT.format(props.cat.volume || 0)} ${props.cat.code || 'CAT'}`} />
    </SpaceConstraintItem>