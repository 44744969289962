import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SpaceConstraintCATTailListItem, SpaceConstraintTable } from '../../components/space-constraint-list/space-constraint-list';
import { SpacePoolTable } from '../../components/spacepool-table/spacepool-table';
import { baseUrl } from '../../shared';
import { CATProfile } from './cat-profile';
import { FORMAT_TAIL, ICATData, NUM_FORMAT } from './cat-utils';

import './cats-page.scss';

export const CATsPage = () => {
    const [cats, setCats] = useState<ICATData[]>([]);

    useEffect(() => {
        fetch(baseUrl + 'api/cats').then(resp => resp.json()).then(data => {
            setCats(data.results);
        });
    }, []);

    return (
        <div className='cats-page'>
            <h3>Chia Asset Tokens</h3>
            <SpacePoolTable
                headerLine={true}
                rowLine={true}
                headers={
                    [
                        { label: 'CAT' },
                        { label: 'Tail', flexible: true, extraClassName: 'monospace' },
                        { label: 'Last Activity' },
                        { label: 'Transactions' },
                        { label: 'Volume' },
                    ]
                }

                rows={cats.map(cat => [
                    <CATProfile cat={cat} />,
                    <Link to={`/cat/${cat.tail}`}>{FORMAT_TAIL(cat.tail)}</Link>,
                    <Link to={`/blocks/${cat.lastBlockHeight}`}>{`${NUM_FORMAT.format(cat.lastBlockHeight)}`}</Link>,
                    `${NUM_FORMAT.format(cat.transactionCount + cat.removalsCount)}`,
                    typeof cat.volume !== 'undefined' ? `${NUM_FORMAT.format(cat.volume / 1000)} ${cat.code || 'CAT'}` : undefined,
                ])}
            />
            <SpaceConstraintTable>
                {cats.filter(x => !!x.logoUrl).map((cat, i) => <SpaceConstraintCATTailListItem key={i} cat={cat} />)}
            </SpaceConstraintTable>
        </div>
    );
};