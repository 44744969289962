import PoolStats, { IPoolStatHistory } from '../models/pool-stats';
import PoolHistory from "../models/PoolHistory";
import quote from "../models/quote";
import { baseUrl } from '../shared'

export const getAPIBaseUrl = (): string => {
    if (document.location.hostname === 'localhost') {
        return baseUrl;
    } else {
        //return '';
        return baseUrl;
    }
}

export const getQuote = (): Promise<quote> => {
    return fetch(
        `${getAPIBaseUrl()}api/stats/prices`
    ).then((response) => response.json());
};

export const getPoolStats = (): Promise<PoolStats> => {
    return fetch(
        `${getAPIBaseUrl()}api/pool`
    ).then((response) => response.json());
};

export const getPoolStatsHistory = (): Promise<{ results: IPoolStatHistory[] }> => {
    return fetch(
        `${getAPIBaseUrl()}api/pool/history`
    ).then((response) => response.json());
};

export const getPoolHistory = (): Promise<PoolHistory> => {
    return fetch(
        `${getAPIBaseUrl()}api/pool/history`
    ).then((response) => response.json());
};

export const postAccountAlias = (launcherId: string, loginToken: string, alias: string): Promise<boolean> => {
    return fetch(
        `${getAPIBaseUrl()}api/account/${launcherId}/alias`,
        {
            method: 'POST',
            body: JSON.stringify({
                alias,
                loginToken,
            }),
        }
    ).then((response) => response.json()).then(resp => !!resp.success);
};
