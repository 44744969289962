import { t } from '@lingui/macro';
import { Trans } from '@lingui/react';
import moment from 'moment';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from '../../index.reducers';


export const ChiaNetspaceChart = (props) => {
    const darkMode = useSelector((state: RootState) => state.topLevel.darkMode);

    let data: { sortKey: number; date: string, total: number }[] = [];
    if (props.stats) {
        let i = 0;
        for (const entry of props.stats) {

            const date = moment.utc(entry.timestamp * 1000);
            data.push({
                sortKey: date.valueOf(),
                date: date.local().format('YYYY/MM/DD HH:mm:ss'),
                total: +(entry.netspace / 1048576).toFixed(2)
            });
        }
        data = data.sort((a, b) => a.sortKey - b.sortKey);
    }

    return (
        <>
        <ResponsiveContainer width="100%" height="100%" >
            <AreaChart data={data}>

                <XAxis dataKey="date" minTickGap={100} tickSize={16} tickFormatter={(date) =>
                    moment.utc(date).format('MM/DD HH:mm:ss')
                } />
                <YAxis unit=" EiB" />
                <CartesianGrid strokeDasharray="3 3" strokeOpacity="0.3" />
                <Tooltip contentStyle={{ backgroundColor: darkMode ? '#000' : '#FFF', opacity: '0.7' }}
                    content={(data) => {
                        const stats = data?.payload;
                        return stats && stats.length > 0 &&
                            <div style={{ backgroundColor: darkMode ? '#000' : '#FFF', opacity: '0.7', borderWidth: 1, borderStyle: 'solid', borderColor: darkMode ? 'white' : 'silver', padding: 20 }}>
                                <div>{moment.utc(stats[0].payload?.date).format('MM/DD HH:mm:ss')}</div>
                                <div style={{ color: 'green', marginBottom: 10 }}>{t`Total Netspace: ${stats[0].payload?.total} EiB`}</div>
                            </div>
                    }} />
                <Legend />
                <Area name={t`Chia Netspace (EiB)`} stackId="4" unit=" EiB" type="monotone" dataKey="total" stroke="green" fillOpacity={1} fill="url(#colorAsia)" />
            </AreaChart>
        </ResponsiveContainer>
        </>
    );
}
