import { t, Trans } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Bar, CartesianGrid, ComposedChart, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { RootState } from "../../index.reducers";
import { actions } from "../../store";
import './statistics-page.scss';
import moment from 'moment';
import { LuckInfoIcon } from '../../components/luck-info-icon/luck-info-icon';
import { MempoolChart } from '../../components/mempool-chart';
import { MempoolInfoIcon } from '../../components/mempool-info-icon';
import { ChiaNetspaceChart } from '../../components/explorer-charts/chia-netspace-chart';
import { MempoolWeightChart } from '../../components/mempool-weight-chart';
import { MempoolWeightInfoIcon } from '../../components/explorer-charts/mempool-weight-info-icon';
import { MempoolSizeChart } from '../../components/explorer-charts/mempool-size-chart';
import { RecentBlockCostChart } from '../../components/explorer-charts/recent-block-cost-chart';
import { baseUrl } from '../../shared'
import { MempoolCostChart } from '../../components/explorer-charts/mempool-cost-chart';
import { FeePerCostChart } from '../../components/explorer-charts/fee-per-cost-chart';
import { ChiaNetspaceIcon } from '../../components/explorer-charts/chia-netspace-icon';
import { BlockCostIcon } from '../../components/explorer-charts/block-cost-icon';
import { FeePerCostIcon } from '../../components/explorer-charts/fee-per-cost-icon';
import { isEmbedMode } from '../../utils/navigator-util';

export const StatisticsPage = () => {
    const history = useHistory();

    const darkMode = useSelector((state: RootState) => state.topLevel.darkMode);
    const [isLoading, setIsLoading] = useState(true);
    const [mempoolStats, setMempoolStats] = useState();
    const [blockStats, setBlockStats] = useState();

    useEffect(() => {
        let url = baseUrl + 'api/stats/mempool'
        fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw Error("error with response:" + response);
            })
            .then((data) => {
                // console.log('data', data)
                setMempoolStats(data.mempoolStats);
            }
            ).catch((err) => {
                console.log(err);
            });

        url = baseUrl + 'api/stats/block'
        fetch(url)
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }

                throw Error("error with response:" + response);
            })
            .then((data) => {
                setBlockStats(data.blockStats);
                setIsLoading(false)
            }
            ).catch((err) => {
                console.log(err);
            });

    }, []);


    //const { statistics } = useSelector((state: RootState) => state.statistics);

    //if (!statistics || statistics?.results.length == 0) {
    //    return null;
    //}

    //var data = statistics?.results.slice().reverse();
    /*for (var i = 0; i<data.length; i++) {
        data[i] = Object.assign({}, data[i]);
        data[i].averageLuck = Math.floor(data[i].averageLuck * 100);
        data[i].peakXchUsd = +(data[i].peakXchUsd).toFixed(2);
        data[i].normalLuck = 100;
    }*/

    if (isLoading && !isEmbedMode) {
        return <p>Loading...</p>
    }

    return (
        <div className='statistics-page'>
            {!isEmbedMode() && <div className="title">
                <h4><Trans>Statistics</Trans></h4>
            </div>}
            <h4 className="sub-title"><span style={{ float: 'left', marginRight: 10 }}>
                <Trans>Chia Mempool Size</Trans></span><MempoolInfoIcon /></h4>
            <div style={{ width: "100%", height: 400, paddingBottom: '30px' }}>
                <MempoolSizeChart stats={mempoolStats} />
            </div>
            <h4 className="sub-title"><span style={{ float: 'left', marginRight: 10 }}>
                <Trans>Chia Netspace</Trans></span><ChiaNetspaceIcon /></h4>
            <div style={{ width: "100%", height: 400, paddingBottom: '30px' }}>
                <ChiaNetspaceChart stats={mempoolStats} />
            </div>
            <h4 className="sub-title"><span style={{ float: 'left', marginRight: 10 }}>
                <Trans>Recent Block Capacity</Trans></span><BlockCostIcon /></h4>
            <div style={{ width: "100%", height: 400, paddingBottom: '30px' }}>
                <RecentBlockCostChart stats={blockStats} />
            </div>
            <h4 className="sub-title"><span style={{ float: 'left', marginRight: 10 }}>
                <Trans>Mempool Capacity</Trans></span><MempoolWeightInfoIcon /></h4>
            <div style={{ width: "100%", height: 400, paddingBottom: '30px' }}>
                <MempoolCostChart stats={mempoolStats} />
            </div>
            <h4 className="sub-title"><span style={{ float: 'left', marginRight: 10 }}>
                <Trans>Average Fee per Cost</Trans></span><FeePerCostIcon /></h4>
            <div style={{ width: "100%", height: 400, paddingBottom: '30px' }}>
                <FeePerCostChart stats={blockStats} />
            </div>


            {/*value={(stats?.fees / stats?.blockCost).toFixed(2) + ' Mojo'}*/}

        </div>
    );
};

