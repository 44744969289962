import { t, Trans } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { IBlockData } from '../../pages/block/block-page';
import { baseUrl, getAddressFromPuzzle } from '../../shared';
import { isEmbedMode, mobilePostMessage } from '../../utils/navigator-util';
import { DateToggleValueWithClass } from '../date-toggle-value';
import { SpaceConstraintBlockListItem, SpaceConstraintTable } from '../space-constraint-list/space-constraint-list';
import { SpacePoolTableNoClass } from '../spacepool-table/spacepool-table-noclass';
import './recent-blocks.scss';

const dateFormat = new Intl.DateTimeFormat(undefined, { dateStyle: 'short', timeStyle: 'short' });

function RecentBlocks(props) {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [hideButton, setHideButton] = useState(false);
    const [loadedBlocks, setLoadedBlocks] = useState<IBlockData[]>([]);
    const [continuationToken, setContinuationToken] = useState('') as any;
    const blocks = [] as IBlockData[];
    const [hidden, setHidden] = useState(false);

    const onNavigateTo = (ev: any) => {
        if (navigator.userAgent.indexOf('SpacePoolApp') >= 0) {
            mobilePostMessage(JSON.stringify({ navigateTo: 'Modal', id: ev.target.dataset.id || '', pagename: ev.target.dataset.pagename }), '*')
        } else {
            history.push(`/${ev.target.dataset.pagename}/${ev.target.dataset.id || ''}`);
        }
        ev.preventDefault();
    };

    function onLoadMore(event) {
        if (event) {
            event.preventDefault();
        }

        const url = baseUrl + `api/blocks` + (continuationToken !== '' ? `/?continuationToken=${continuationToken}` : '');

        fetch(url)
            .then((response) => {
                if (response.ok)
                    return response.json();
            })
            .then((data) => {
                for (const key in data.blocks) {
                    const block = {
                        ...data.blocks[key],
                    };

                    block.farmerAddress = getAddressFromPuzzle(block.farmerPuzzleHash)
                    blocks.push(block);
                }
                if (Object.keys(data.continuationToken).length !== 0) {
                    setContinuationToken(data.continuationToken);
                } else {
                    setHideButton(true);
                }
                setLoadedBlocks((loadedBlocks) => [...loadedBlocks, ...blocks]);
            });
    }


    useEffect(() => onLoadMore(null), []);

    if (isLoading && !isEmbedMode()) {
        return (
            <div>
                <p>Loading...</p>
            </div>
        );
    }

    function toggleHidden() {
        setHidden(!hidden)
    }

    return (
        <div className='recent-blocks page'>
            <div className="recent-blocks-spacious">
                <SpacePoolTableNoClass
                    headerLine={true}
                    rowLine={true}
                    headers={[
                        {
                            label: <span className="toggle-header" onClick={toggleHidden}>{hidden ? <span><span style={{ opacity: '50%' }}>All</span> <span> | </span> <span>Txs Only</span></span> : <span><span>All</span> <span> | </span> <span style={{ opacity: '50%' }}>Txs Only</span></span>} </span>,
                            extraClassName: 'label-block',
                        },
                        {
                            label: t`Farmer`,
                            extraClassName: 'label-farmer monospace',
                            flexible: true,
                        },
                        {
                            label: t`Txs`,
                            extraClassName: 'label-tx-count text-align-end',
                        },
                        {
                            label: t`Rewards`,
                            extraClassName: 'label-reward-count text-align-end',
                        },
                        {
                            label: [t`Age`, t`Date`],
                            extraClassName: 'label-date text-align-end',
                        },
                    ]}

                    rows={loadedBlocks.map((row, i) => {
                        if (row.rewardCount === 0) {
                            return [
                                <div className={hidden ? "hidden spacepool-table-column-value" : "spacepool-table-column-value"}><Link to={'/blocks/' + row.blockHeight}>{row.blockHeight.toLocaleString()}</Link></div>,
                                <div className={hidden ? "hidden spacepool-table-column-value" : "spacepool-table-column-value"}><Link to={'/addresses/' + getAddressFromPuzzle(row.farmerPuzzleHash)}>{getAddressFromPuzzle(row.farmerPuzzleHash)}</Link> </div>,
                                <div className={hidden ? "hidden spacepool-table-column-value" : "spacepool-table-column-value"}></div>,
                                <div className={hidden ? "hidden spacepool-table-column-value" : "spacepool-table-column-value"}></div>,
                                <div className={hidden ? "hidden spacepool-table-column-value" : "spacepool-table-column-value"}></div>,
                            ]
                        }
                        return [
                            <div className='spacepool-table-column-value'><a href="/" onClick={onNavigateTo} data-pagename="blocks" data-id={row.blockHeight}>{row.blockHeight.toLocaleString()}</a></div>,
                            <div className='spacepool-table-column-value'><a href="/" onClick={onNavigateTo} data-pagename="addresses" data-id={getAddressFromPuzzle(row.farmerPuzzleHash)}>{getAddressFromPuzzle(row.farmerPuzzleHash)}</a> </div>,
                            <div className='spacepool-table-column-value'>{row.transactionBlock ? <div>{(row.transactionCount + row.removalsCount) + (row.removalsCountCats ? '*' : '')}</div> : ''}</div>,
                            <div className='spacepool-table-column-value'>{row.rewardCount / 2}</div>,
                            DateToggleValueWithClass(row.timestamp),
                        ]
                    })}
                />
            </div>
            <SpaceConstraintTable>
                {loadedBlocks.map((row, i) => <SpaceConstraintBlockListItem key={i} block={row} />)}
            </SpaceConstraintTable>

            <div className="view-all">
                <a href="#" onClick={onLoadMore}>
                    <button
                        disabled={isLoading}
                        hidden={hideButton}
                        className="primary"
                    >
                        <Trans>Load More</Trans>
                    </button>
                </a>
            </div>
        </div>
    );
}

export default RecentBlocks;
