import { Trans } from '@lingui/macro';
import ReactMarkdown from 'react-markdown';
import './terms-page.scss';

export const TermsPage = () => {
    const terms = `EXCEPT AS EXPRESSLY PROVIDED FOR IN THIS AGREEMENT, TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, THE COMPANY, ITS AFFILIATES, AND ITS SUPPLIERS DO NOT MAKE ANY OTHER WARRANTY OF ANY KIND, WHETHER EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR USE AND NON-INFRINGEMENT. THE COMPANY, ITS AFFILIATES, AND ITS SUPPLIERS ARE NOT RESPONSIBLE OR LIABLE FOR THE DELETION, FAILURE TO STORE, OR ANY LOSS OF ANY USER DATA, INCLUDING BLOCKCHAIN DATA. NEITHER THE COMPANY, ITS AFFILIATES, NOR ITS SUPPLIERS, WARRANTS THAT THE OPERATION OF THE SOFTWARE OR THE SERVICES WILL BE ERROR-FREE OR UNINTERRUPTED. THE COMPANY, ITS AFFILIATES, AND ITS SUPPLIERS ARE NOT RESPONSIBLE OR LIABLE FOR ANY LOSSES OR OPPORTUNITY COSTS RESULTING FROM BLOCKCHAIN NETWORK AND PROTOCOL OR THIRD-PARTY SOFTWARE ISSUES. WE MAKE NO GUARANTEES REGARDING THE ACCURACY, COMPLETENESS, RELIABILITY, TIMELINESS OF ANY BLOCKCHAIN-RELATED DATA PROVIDED BY THIS SERVICE, NOR DO WE MAKE GUARANTEES REGARDING IT BEING ERROR-FREE. WE ARE NOT LIABLE FOR ANY LOSSES OR OPPORTUNITY COSTS RESULTING FROM USE OF THIS DATA.
    `;
    return (
        <div className='terms-page'>
            <h4><Trans>Terms</Trans></h4>
            <div className='terms-page-content'>
                <div className="terms-date">
                August 1st, 2022
                </div><br />
                <ReactMarkdown children={terms} />
            </div>
        </div>
    );
};