import { useState } from "react";
//inspo https://www.emgoto.com/react-inline-edit/
function EditComplicated(props) {
    const [initialValue, setInitialValue] = useState(props.value)
    const [editingValue, setEditingValue] = useState(props.value);
    const onChange = (event) => {
      
      props.onChange(); //if anything custom needs to happen like change state
      setEditingValue(event.target.value)
    };
  
  const onKeyDownNoFetch = (event) => {
    if (event.key === "Enter") {
      try {
      let result = props.save(event.target.value, props.address)  
      setInitialValue(event.target.value)
      event.target.blur();
      }
      catch {
          setEditingValue(initialValue)
          event.target.blur();
      }
    }
  else if(event.key==="Escape"){
      setEditingValue(initialValue)
      event.target.blur();
  }
}

function onBlur(event){
  props.onBlur(event.target.value)
}
    return (
      <input
        style={props.style}
        type="text"
        aria-label="Field name"
        value={editingValue}
        onChange={(onChange)}
        onKeyDown={onKeyDownNoFetch}
        placeholder={props.placeholder}
        onBlur={onBlur}
        size={props.size}
      />
    )
  }

export default EditComplicated;