import { t, Trans } from '@lingui/macro';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IBlockData } from '../../pages/block/block-page';
import { baseUrl, getAddressFromPuzzle } from '../../shared';
import { isEmbedMode, mobilePostMessage } from '../../utils/navigator-util';
import { DateToggleValueWithClass } from '../date-toggle-value';
import { SpaceConstraintBlockListItem, SpaceConstraintTable } from '../space-constraint-list/space-constraint-list';
import { SpacePoolTableNoClass } from '../spacepool-table/spacepool-table-noclass';
import './recent-blocks.scss';

const dateFormat = new Intl.DateTimeFormat(undefined, { dateStyle: 'short', timeStyle: 'short' });

function RecentBlocksRefresh(props) {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [hideButton, setHideButton] = useState(false);
    const [loadedBlocks, setLoadedBlocks] = useState<IBlockData[]>([]);
    const [hidden, setHidden] = useState(false);

    const onNavigateTo = (ev: any) => {
        if (navigator.userAgent.indexOf('SpacePoolApp') >= 0) {
            mobilePostMessage(JSON.stringify({ navigateTo: 'Modal', id: ev.target.dataset.id || '', pagename: ev.target.dataset.pagename }), '*')
        } else {
            history.push(`/${ev.target.dataset.pagename}/${ev.target.dataset.id || ''}`);
        }
        ev.preventDefault();
    };

    const onNavigateToAllBlocks = (ev: any) => {
        if (navigator.userAgent.indexOf('SpacePoolApp') >= 0) {
            mobilePostMessage(JSON.stringify({ navigateTo: 'Modal', id: '', pagename: 'blocks' }), '*')
        } else {
            history.push("/blocks");
        }
        ev.preventDefault();
    };

    function onLoadMore(event) {
        if (event) {
            event.preventDefault();
        }

        const url = baseUrl + `api/blocks`;

        fetch(url)
            .then((response) => {
                if (response.ok)
                    return response.json();
            })
            .then((data) => {
                // console.log(data.blocks)
                for (const key in data.blocks) {
                    const block = data.blocks[key];
                    block.farmerAddress = getAddressFromPuzzle(block.farmerPuzzleHash)
                }
                setLoadedBlocks(data.blocks);
            });
    }

    let intervalID;
    useEffect(() => {
        onLoadMore(null)
        intervalID = setInterval(() => {
            if (!document.hidden) {
                onLoadMore(null)
            }
        }, 10000);

        return () => clearInterval(intervalID);
    }, []);

    if (isLoading && !isEmbedMode()) {
        return (
            <div>
                <p>Loading...</p>
            </div>
        );
    }

    function toggleHidden() {
        setHidden(!hidden)
    }

    return (
        <div className='recent-blocks'>
            <h4>Recent Blocks</h4>
            <div className="recent-blocks-spacious">
                <SpacePoolTableNoClass
                    headerLine={true}
                    rowLine={true}
                    headers={[
                        {
                            label: <span className="toggle-header" onClick={toggleHidden}>{hidden ? <span><span style={{ opacity: '50%' }}>All</span> <span> | </span> <span>Txs Only</span></span> : <span><span>All</span> <span> | </span> <span style={{ opacity: '50%' }}>Txs Only</span></span>} </span>,
                            extraClassName: 'label-block',
                        },
                        {
                            label: t`Farmer`,
                            extraClassName: 'label-farmer monospace',
                            flexible: true,
                        },
                        {
                            label: t`Txs`,
                            extraClassName: 'label-tx-count text-align-end',
                        },
                        {
                            label: t`Rewards`,
                            extraClassName: 'label-reward-count text-align-end',
                        },
                        {
                            label: [t`Age`, t`Timestamp`],
                            extraClassName: 'label-date text-align-end',
                        },
                    ]}

                    rows={loadedBlocks.map((row, i) => {
                        if (row.rewardCount === 0) {
                            return [
                                <div className={hidden ? "hidden spacepool-table-column-value" : "spacepool-table-column-value"}><a href='/' onClick={onNavigateTo} data-id={row.blockHeight} data-pagename="blocks">{row.blockHeight.toLocaleString()}</a></div>,
                                <div className={hidden ? "hidden spacepool-table-column-value" : "spacepool-table-column-value"}><a href='/' onClick={onNavigateTo} data-id={getAddressFromPuzzle(row.farmerPuzzleHash)} data-pagename="addresses">{getAddressFromPuzzle(row.farmerPuzzleHash)}</a> </div>,
                                <div className={hidden ? "hidden spacepool-table-column-value" : "spacepool-table-column-value"}></div>,
                                <div className={hidden ? "hidden spacepool-table-column-value" : "spacepool-table-column-value"}></div>,
                                <div className={hidden ? "hidden spacepool-table-column-value" : "spacepool-table-column-value"}></div>,
                            ]
                        }
                        return [
                            <div className='spacepool-table-column-value'><a href='/' onClick={onNavigateTo} data-id={row.blockHeight} data-pagename="blocks">{row.blockHeight.toLocaleString()}</a></div>,
                            <div className='spacepool-table-column-value'><a href='/' onClick={onNavigateTo} data-id={getAddressFromPuzzle(row.farmerPuzzleHash)} data-pagename="addresses">{getAddressFromPuzzle(row.farmerPuzzleHash)}</a> </div>,
                            <div className='spacepool-table-column-value'>{row.transactionBlock ? <div>{(row.transactionCount + row.removalsCount) + (row.removalsCountCats ? '*' : '')}</div> : ''}</div>,
                            <div className='spacepool-table-column-value'>{row.rewardCount / 2}</div>,
                            DateToggleValueWithClass(row.timestamp),
                        ]
                    })}
                />
            </div>
            <SpaceConstraintTable>
                {loadedBlocks.map((row, i) => <SpaceConstraintBlockListItem key={i} block={row} />)}
            </SpaceConstraintTable>

            <div className="view-all">
                <a href="/" onClick={onNavigateToAllBlocks}>
                    <button
                        disabled={isLoading}
                        hidden={hideButton}
                        className="primary"
                    >
                        <Trans>View Blocks</Trans>
                    </button>
                </a>
            </div>
        </div>
    );
}

export default RecentBlocksRefresh;
